import { toast, ToastOptions } from 'react-toastify';
import './index.css';
import DoneImage from '../../assets/images/done.png';
import ErrorImage from '../../assets/images/error.png';

const ToasterError = ({ message }: { message: string }) => {
    return (
        <div className={'toster-old--error--container'}>
            <img
                src={ErrorImage}
                alt='...'
                className='mr-2 w-6 h-6'
            />
            {message}
        </div>
    );
};

const ToasterSuccess = ({ message }: { message: string }) => {
    return (
        <div className={'toster-old--success--container'}>
            <img
                src={DoneImage}
                alt='...'
                className='mr-2 w-6 h-6'
            />
            {message}
        </div>
    );
};

const tosterConf: ToastOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
};

const Toaster = {
    error: (message: string) => {
        toast.error(<ToasterError message={message} />, tosterConf);
    },
    success: (message: string) => {
        toast.success(<ToasterSuccess message={message} />, tosterConf);
    },
    info: (message: string) => {
        toast.info(<ToasterSuccess message={message} />, tosterConf);
    },
};

export default Toaster;
