import { useEffect, useState } from 'react';
import Api from './utils/api';
import App from './Components/App';
import OldApp from './ComponentsOld/App';
import { LTTheme, LTThemeProvider } from "@lambdatestincprivate/lt-components";
import { setAccessibilityEnabled } from './redux/features/configSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';

export default function FeatureFlag() {
    const [loading, setLoading] = useState(true);
    const [showOldUI, setShowOldUI] = useState(false);
    const dispatch = useDispatch();

	const accessibilityEnabled = useSelector(
        (state: RootState) => state.config.accessibilityEnabled
    );
    
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await Api.commonApi.getfeatureflag();
                if (res?.result['integrations_primer']?.variant?.key === 'off')
                    setShowOldUI(true);

                if (res?.result['lt_accessibility_flag']?.variant?.key === 'on') {
                    dispatch(setAccessibilityEnabled(true));
                }

                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return loading ? 
		<></> : 
		showOldUI ? 
		<OldApp /> : 
		<LTThemeProvider 
			theme={LTTheme}
			colorMode="light"
			dayScheme={accessibilityEnabled ? "light_high_contrast" : "light"}
		>
			<App />
		</LTThemeProvider>;
}
