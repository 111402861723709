import { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IListItem, IMyListItem, newUIOnlyCodes } from '../assets/constants/constants';
import Icon from '../assets/images';
import {
    setWebexOptions,
    setZohoCliqOptions,
    setGoogleChatOptions,
    setInstructionState,
    setPlugins,
    setPreferences,
    setSideBarObject,
    setAzureDevOpsOptions,
    setJiraOptions,
} from '../redux/features/configSlice';
import { RootState } from '../redux/store';
import Api from '../utils/api';
import {
    checkValidInput,
    filterInactivePlugins,
    redirectToLogin,
    searchCode,
    searchRequestedIntegration,
    sendAmplitudeEvents,
} from '../utils/helper';
import { Card } from './Card';
import { InstalledCard } from './InstalledCard';
import Sidebar from './Sidebar';
import Toaster from './Toaster/Index';
import { useNavigate } from 'react-router-dom';
import { EventNames } from '../utils/events';

function Home(): JSX.Element {
    const [myIntegrations, setMyIntegrations] = useState<IMyListItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [requestIntegration, setRequestIntegration] = useState('');
    const [bugTracker, setBugTracker] = useState<IListItem[]>([]);
    const [projectManagement, setProjectManagement] = useState<IListItem[]>([]);
    const [analytics, setAnalytics] = useState<IListItem[]>([]);
    const [communication, setCommunication] = useState<IListItem[]>([]);
    const [cicdTools, setCicdTols] = useState<IListItem[]>([]);
    const [codelessAutomationPlatform, setCodelessAutomationPlatform] =
        useState<IListItem[]>([]);
    const [codelessAutomationTools, setCodelessAutomationTools] = useState<
        IListItem[]
    >([]);
    const [testReporting, setTestReporting] = useState<IListItem[]>([]);
    const [thirdParty, setThirdParty] = useState<IListItem[]>([]);
    const [pluginsAndExtension, setPluginsAndExtension] = useState<IListItem[]>(
        []
    );

    const sideBarObject = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const plugins = useSelector((state: RootState) => state.config.plugins);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchList() {
            if (window.location.pathname.includes('/auth/callback')) {
                try {
                    const code = window.location.pathname.split('/')[1];
                    let queryString = window.location.search;
                    const hashString = window.location.hash;
                    if (hashString.length > 0) {
                        queryString = hashString.replaceAll('#', '?');
                    }
                    const res = await Api.installApi.oauthCallback(
                        code,
                        queryString
                    );
                    if (res.message) {
                        Toaster.success(res.message);
                    }
                    if (code === 'google-chat') {
                        dispatch(setGoogleChatOptions(res));
                        navigate('/google-chat/install');
                        return;
                    }
                    if (code === 'webex') {
                        dispatch(setWebexOptions(res));
                        navigate('/webex/install');
                        return;
                    }
                    if (code === 'azure-devops') {
                        dispatch(setAzureDevOpsOptions(res));
                        navigate('/azure-devops/install');
                        return;
                    }
                    if (code === 'zoho-cliq') {
                        dispatch(setZohoCliqOptions(res));
                        navigate('/zoho-cliq/install');
                        return;
                    }
                    if (code === 'jira') {
                        dispatch(setJiraOptions(res));
                        navigate('/jira/install');
                        return;
                    }
                } catch (err: any) {
                    if (err.response && err.response.data)
                        Toaster.error(err.response.data.message);
                }
                window.history.pushState({}, '', '/');
            }
            try {
                const list = await Api.commonApi.fetchList();
                dispatch(setPlugins(filterInactivePlugins(list)));
                const res = await Api.commonApi.getPreferences();
                const buildNotificationApi =
                    await Api.commonApi.getBuildNotificationTime();
                if (
                    res.data &&
                    res.data.preferences &&
                    buildNotificationApi.build_notification_time
                ) {
                    dispatch(
                        setPreferences({
                            ...res.data.preferences,
                            buildNotificationTime:
                                buildNotificationApi.build_notification_time,
                        })
                    );
                }
                const sidebarObj = searchCode(list);
                if (Object.keys(sidebarObj).length > 0) {
                    if (!sidebarObj.is_installed) {
                        dispatch(setSideBarObject(sidebarObj));
                    } else {
                        window.history.pushState({}, '', '/');
                    }
                }
            } catch (err: any) {
                if (err.response && err.response.data)
                    Toaster.error(err.response.data.message);
                if (err.response && err.response.status === 401) {
                    redirectToLogin();
                }
            }
        }
        fetchList();
    }, [dispatch, navigate]);

    useEffect(() => {
        setMyIntegrations(plugins['MY INTEGRATIONS']);
        setBugTracker(plugins['BUG TRACKER']);
        setProjectManagement(plugins['PROJECT MANAGEMENT']);
        setAnalytics(plugins['ANALYTICS']);
        setCommunication(plugins['COMMUNICATION']);
        setCicdTols(plugins['CI CD TOOLS']);
        setCodelessAutomationPlatform(plugins['CODELESS AUTOMATION PLATFORM']);
        setCodelessAutomationTools(plugins['CODELESS AUTOMATION TOOLS']);
        setTestReporting(plugins['TEST REPORTING TOOLS']);
        setThirdParty(plugins['3RD PARTY TOOLS']);
        setPluginsAndExtension(plugins['PLUGINS AND EXTENSIONS']);
    }, [plugins]);

    useEffect(() => {
        const mainBody = document.getElementById('main_body');
        mainBody?.scrollTo(0, mainBody.scrollHeight);
    }, [expand]);

    const searchList = (e: React.ChangeEvent<any>) => {
        setMyIntegrations(
            plugins['MY INTEGRATIONS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setBugTracker(
            plugins['BUG TRACKER'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setProjectManagement(
            plugins['PROJECT MANAGEMENT'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setAnalytics(
            plugins['ANALYTICS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setCommunication(
            plugins['COMMUNICATION'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setCicdTols(
            plugins['CI CD TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setCodelessAutomationPlatform(
            plugins['CODELESS AUTOMATION PLATFORM'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setCodelessAutomationTools(
            plugins['CODELESS AUTOMATION TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setTestReporting(
            plugins['TEST REPORTING TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setThirdParty(
            plugins['3RD PARTY TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setPluginsAndExtension(
            plugins['PLUGINS AND EXTENSIONS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    const totalIntegrations = () => {
        let count = 0;
        count += plugins['BUG TRACKER'].length;
        count += plugins['PROJECT MANAGEMENT'].length;
        count += plugins['ANALYTICS'].length;
        count += plugins['COMMUNICATION'].length;
        count += plugins['CI CD TOOLS'].length;
        count += plugins['CODELESS AUTOMATION PLATFORM'].length;
        count += plugins['CODELESS AUTOMATION TOOLS'].length;
        count += plugins['TEST REPORTING TOOLS'].length;
        count += plugins['3RD PARTY TOOLS'].length;
        count += plugins['PLUGINS AND EXTENSIONS'].length;
        return count;
    };

    const cardButtonClick = async (element: IListItem) => {
        if (element.is_installed) {
            return;
        }
        sendAmplitudeEvents(EventNames.INT_SELECT_INTEGRATION, {
            category: element.category,
            plugin_name: element.name,
        });
        if (
            element.code.startsWith('https://') ||
            element.code.startsWith('http://')
        ) {
            window.open(element.code, '_blank')?.focus();
            return;
        } else {
            dispatch(setSideBarObject(element));
        }
    };

    const handleRequestPlugin = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (requestIntegration.length === 0) {
            return;
        }
        const sidebarObj = await searchRequestedIntegration(
            plugins,
            requestIntegration
        );
        if (sidebarObj && Object.keys(sidebarObj).length > 0) {
            Toaster.success('We already have this integration');
            if (
                !sidebarObj.is_installed &&
                !(
                    sidebarObj.code.startsWith('https://') ||
                    sidebarObj.code.startsWith('http://')
                )
            ) {
                dispatch(setSideBarObject(sidebarObj));
            }
            setRequestIntegration('');
            return;
        }
        setIsLoading(true);
        try {
            const res = await Api.commonApi.requestIntegration({
                integration: requestIntegration,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            setRequestIntegration('');
        } catch (err: any) {
            setIsLoading(false);
            setRequestIntegration('');
            if (err.response && err.response.data)
                Toaster.error(err.response.data.message);
        }
    };

    return (
        <main
            id='main_body'
            className='p-3 text-neutral-600 main_body oldUI'
        >
            {totalIntegrations() > 0 && (
                <label className='relative text-zinc-600 focus-within:text-zinc-800 block mb-5'>
                    <img
                        src={Icon.searchIcon}
                        alt=''
                        className='pointer-events-none w-3 h-3 absolute top-1/2 transform -translate-y-1/2 left-3'
                    />
                    <input
                        type='text'
                        aria-label='Search'
                        placeholder={`Search from more than ${totalIntegrations()} apps`}
                        className='border-gray-300 border-1 placeholder-neutral-500 w-80 pl-8 focus:outline-none h-8 rounded-sm text-xs'
                        onChange={searchList}
                    />
                </label>
            )}
            <div className='row'>
                {myIntegrations.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            MY INTEGRATIONS
                        </h2>
                        <ul className='pl-0'>
                            {myIntegrations.map((element, index) => {
                                return (
                                    <li
                                        key={index}
                                        className='inline-block w-[300px] h-[148px] mb-4 mr-4'
                                    >
                                        <InstalledCard
                                            logo={element.logo}
                                            name={element.name}
                                            code={element.code}
                                            category={element.category}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </section>
                )}
                {bugTracker.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            BUG TRACKER
                        </h2>
                        <ul className='pl-0'>
                            {bugTracker.map((element, index) => {
                                return element.is_published && !newUIOnlyCodes.includes(element.code) ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {projectManagement.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            PROJECT MANAGEMENT
                        </h2>
                        <ul className='pl-0'>
                            {projectManagement.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {analytics.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            ANALYTICS
                        </h2>
                        <ul className='pl-0'>
                            {analytics.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {communication.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            COMMUNICATION
                        </h2>
                        <ul className='pl-0'>
                            {communication.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {cicdTools.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            CI CD TOOLS
                        </h2>
                        <ul className='pl-0'>
                            {cicdTools.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {codelessAutomationPlatform.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            CODELESS AUTOMATION PLATFORM
                        </h2>
                        <ul className='pl-0'>
                            {codelessAutomationPlatform.map(
                                (element, index) => {
                                    return element.is_published ? (
                                        <li className='inline-block'>
                                            <button
                                                key={index}
                                                className='inline-block w-32 h-[120px] mb-4 mr-4'
                                                onClick={() =>
                                                    cardButtonClick(element)
                                                }
                                                aria-label={
                                                    element.is_installed
                                                        ? `Installed ${element.name}`
                                                        : `Add ${element.name}`
                                                }
                                            >
                                                <Card
                                                    logo={element.logo}
                                                    name={element.name}
                                                    code={element.code}
                                                    installed={
                                                        element.is_installed
                                                    }
                                                    category={element.category}
                                                />
                                            </button>
                                        </li>
                                    ) : (
                                        ''
                                    );
                                }
                            )}
                        </ul>
                    </section>
                )}
                {codelessAutomationTools.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            CODELESS AUTOMATION TOOLS
                        </h2>
                        <ul className='pl-0'>
                            {codelessAutomationTools.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {testReporting.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            TEST REPORTING TOOLS
                        </h2>
                        <ul className='pl-0'>
                            {testReporting.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {thirdParty.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            3RD PARTY TOOLS
                        </h2>
                        <ul className='pl-0'>
                            {thirdParty.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
                {pluginsAndExtension.length > 0 && (
                    <section className='col-12'>
                        <h2 className='mb-3 text-sm font-normal tracking-[2px]'>
                            PLUGINS AND EXTENSIONS
                        </h2>
                        <ul className='pl-0'>
                            {pluginsAndExtension.map((element, index) => {
                                return element.is_published ? (
                                    <li className='inline-block'>
                                        <button
                                            key={index}
                                            className='inline-block w-32 h-[120px] mb-4 mr-4'
                                            onClick={() =>
                                                cardButtonClick(element)
                                            }
                                            aria-label={
                                                element.is_installed
                                                    ? `Installed ${element.name}`
                                                    : `Add ${element.name}`
                                            }
                                        >
                                            <Card
                                                logo={element.logo}
                                                name={element.name}
                                                code={element.code}
                                                installed={element.is_installed}
                                                category={element.category}
                                            />
                                        </button>
                                    </li>
                                ) : (
                                    ''
                                );
                            })}
                        </ul>
                    </section>
                )}
            </div>
            <Offcanvas
                show={Object.keys(sideBarObject).length > 0}
                onHide={() => {
                    dispatch(setInstructionState('Instructions'));
                    dispatch(setSideBarObject({}));
                }}
                placement='end'
                style={{ minWidth: '880px' }}
            >
                {Object.keys(sideBarObject).length > 0 && <Sidebar />}
            </Offcanvas>
            {totalIntegrations() > 0 && (
                <form
                    className='w-full'
                    id='request'
                    onSubmit={handleRequestPlugin}
                >
                    <div className='flex text-xs text-black font-medium mb-3'>
                        Couldn’t find what you’re looking for?&nbsp;
                        {!expand && (
                            <button
                                className='cursor-pointer underline font-semibold'
                                onClick={() => setExpand(true)}
                            >
                                Request Integration
                            </button>
                        )}
                    </div>
                    {expand && (
                        <div className='flex gap-x-2'>
                            <label className='relative text-zinc-600 focus-within:text-gray-800 block'>
                                <img
                                    src={Icon.addIntegrationIcon}
                                    alt='Search Icon'
                                    className='pointer-events-none w-3 h-3 absolute top-1/2 transform -translate-y-1/2 left-3'
                                />
                                <input
                                    type='text'
                                    aria-label='InputText'
                                    placeholder='Request new integration here'
                                    className='border-1  border-gray-300 placeholder-neutral-400 w-96 pl-8 focus:outline-none h-8 rounded-sm text-xs'
                                    value={requestIntegration}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        if (
                                            !val.startsWith(' ') &&
                                            val.length < 51
                                        )
                                            setRequestIntegration(val);
                                    }}
                                />
                            </label>
                            <button
                                type='submit'
                                className={`py-1.5 px-3 rounded-sm flex items-center text-[10px] h-8 ${
                                    checkValidInput(requestIntegration)
                                        ? 'bg-neutral-900 text-white cursor-pointer'
                                        : 'bg-gray-200 text-zinc-800'
                                }`}
                                form='request'
                                disabled={!checkValidInput(requestIntegration)}
                            >
                                {isLoading && (
                                    <img
                                        width='14px'
                                        src={Icon.loaderGif}
                                        alt='loader'
                                        className='mr-1'
                                    />
                                )}
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            )}
            <div
                className='absolute left-[-999999px]'
                aria-live='polite'
                aria-atomic='true'
            >
                <span>
                    {bugTracker.length +
                        projectManagement.length +
                        analytics.length +
                        communication.length +
                        cicdTools.length +
                        codelessAutomationPlatform.length +
                        codelessAutomationTools.length +
                        testReporting.length +
                        thirdParty.length +
                        pluginsAndExtension.length}{' '}
                    Results found
                </span>
            </div>
        </main>
    );
}

export default Home;
