import Error404 from '../assets/images/404.svg';

export default function ErrorPage() {
    return (
        <div
            className='md:flex min-h-screen'
            style={{ fontFamily: 'Nunito, sans-serif' }}
        >
            <div className='w-full md:w-1/2 bg-white flex items-center justify-center'>
                <div className='max-w-md m-8'>
                    <div className='text-black text-5xl md:text-[144px] font-black'>
                        404
                    </div>
                    <div className='w-16 h-1 bg-violet-400 my-3 md:my-6'></div>
                    <p
                        className='text-gray-500 text-2xl md:text-3xl font-light mb-8'
                        style={{ lineHeight: 1.5 }}
                    >
                        Sorry, the page you are looking for could not be found.
                    </p>
                    <a
                        href='/'
                        className='text-inherit no-underline hover:text-inherit'
                    >
                        <button className='bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-grey-light hover:border-gray-300 rounded-lg'>
                            Go Home
                        </button>
                    </a>
                </div>
            </div>
            <div className='md:relative pb-full md:flex md:pb-0 md:min-h-screen w-full md:w-1/2'>
                <div
                    style={{
                        backgroundImage: `url(${Error404})`,
                        height: '100%',
                    }}
                    className='absolute pin bg-cover bg-no-repeat md:bg-left lg:bg-center w-full'
                ></div>
            </div>
        </div>
    );
}
