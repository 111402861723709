import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LeftPanel } from '../../Wrappers';
import { setInstructionState } from '../../../redux/features/configSlice';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import {
    sanitizeAndRedirect,
    sendAmplitudeEvents,
    updatePluginList,
} from '../../../utils/helper';
import { EventNames } from '../../../utils/events';

export default function Github() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('OAuth');
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [isLoading, setIsLoading] = useState(false);
    const [host, setHost] = useState('');
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                host,
                token,
                instance_type: instanceType,
            });
            if (instanceType === 'OAuth') {
                sanitizeAndRedirect(res.url);
            }
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <div className='flex flex-col space-y-2'>
                    <Label>Instance</Label>
                    <div className='flex flex-row space-x-5 '>
                        <div className='flex flex-row space-x-1 items-center'>
                            <input
                                type='radio'
                                aria-label='InputRadio'
                                checked={instanceType === 'OAuth'}
                                readOnly
                                onClick={() => {
                                    setInstanceType('OAuth');
                                    dispatch(
                                        setInstructionState('Instructions')
                                    );
                                }}
                            />
                            <span>OAuth</span>
                        </div>
                        <div className='flex flex-row space-x-1 items-center'>
                            <input
                                type='radio'
                                aria-label='InputRadio'
                                checked={instanceType === 'Cloud'}
                                readOnly
                                onClick={() => {
                                    setInstanceType('Cloud');
                                    dispatch(
                                        setInstructionState('Instructions')
                                    );
                                }}
                            />
                            <span>Cloud</span>
                        </div>
                        <div className='flex flex-row space-x-1 items-center'>
                            <input
                                type='radio'
                                aria-label='InputRadio'
                                checked={instanceType === 'SelfHosted'}
                                readOnly
                                onClick={() => {
                                    setInstanceType('SelfHosted');
                                    dispatch(
                                        setInstructionState(
                                            'Instructions(SelfHosted)'
                                        )
                                    );
                                }}
                            />
                            <span>Self Hosted</span>
                        </div>
                    </div>
                </div>
                {instanceType === 'SelfHosted' && (
                    <div className='flex flex-col space-y-2'>
                        <Label>Domain</Label>
                        <div className='flex border-1  border-gray-300 rounded-sm'>
                            <input
                                type='text'
                                id='url'
                                aria-label='Url'
                                className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                placeholder='e.g., https://github.mydomain.com'
                                value={host}
                                onChange={(e) => setHost(e.target.value)}
                                required
                            />
                        </div>
                        {(errors as any).host && (
                            <small className='w-full h-2.5 text-[10px] text-red-600'>
                                {(errors as any).host &&
                                    (errors as any).host[0]}
                            </small>
                        )}
                    </div>
                )}
                {(instanceType === 'Cloud' ||
                    instanceType === 'SelfHosted') && (
                    <div className='flex flex-col space-y-2'>
                        <Label>API Token</Label>
                        <div className='flex border-1  border-gray-300 rounded-sm'>
                            <input
                                type='password'
                                aria-label='Password'
                                id='token'
                                className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                placeholder=''
                                onChange={(e) => setToken(e.target.value)}
                                value={token}
                                required
                            />
                        </div>
                        {(errors as any).token && (
                            <small className='w-full h-2.5 text-[10px] text-red-600'>
                                {(errors as any).token &&
                                    (errors as any).token[0]}
                            </small>
                        )}
                    </div>
                )}
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                >
                    {isLoading && (
                        <img
                            width='14px'
                            src={Icons.loaderGif}
                            alt='loader'
                            className='mr-1'
                        />
                    )}
                    Install
                </button>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'>
                <Label>Know how to generate</Label>
                <a
                    href='https://www.lambdatest.com/support/docs/github-integration/'
                    target='_blank'
                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                    rel='noreferrer'
                >
                    Personal Access Token
                </a>
            </div>
        </LeftPanel>
    );
}
