const Icons: { [index: string]: any } = {
    searchIcon: require('./filter-search.svg').default,
    addPlusAqua: require('./add-plus-aqua.svg').default,
    closeIcon: require('./close-icon.svg').default,
    upArrow: require('./up-arrow.svg').default,
    downArrow: require('./down-arrow.svg').default,
    checkIcon: require('./check.svg').default,
    refreshIcon: require('./refresh.svg').default,
    removeIcon: require('./remove-icon.svg').default,
    resyncIcon: require('./resync-icon.svg').default,
    settingsIcon: require('./settings-icon.svg').default,
    correctIcon: require('./done.png').default,
    errorIcon: require('./error.png').default,
    loaderGif: require('./loader-white.gif'),
    blackLoaderGif: require('./loader-black.gif'),
    redLoaderGif: require('./loader-red.gif'),
    addIntegrationIcon: require('./add-integration.svg').default,
};
export default Icons;
