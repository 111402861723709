import { useState } from 'react';
import Icons from '../assets/images';
import Api from '../utils/api';
import { updatePluginList } from '../utils/helper';
import Toaster from './Toaster/Index';
import { jiraCode, webhookCode } from '../assets/constants/constants';
import { useDispatch } from 'react-redux';
import { setSideBarObject } from '../redux/features/configSlice';

export function Card({
    name,
    logo,
    code,
    installed,
    category,
}: {
    name: string;
    logo: string;
    code: string;
    installed: boolean;
    category: string;
}) {
    const [hover, setHover] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);

    const dispatch = useDispatch();

    const handleRemove = async (e: React.ChangeEvent<any>) => {
        if (installed === false) {
            return;
        }
        setRemoveLoading(true);
        e.stopPropagation();
        try {
            const res = await Api.commonApi.removePlugin(code);
            if (res.message) {
                Toaster.success(res.message);
            }
            setRemoveLoading(false);
            await updatePluginList();
        } catch (err: any) {
            setRemoveLoading(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const handleSync = async (e: React.ChangeEvent<any>) => {
        if (installed === false) {
            return;
        }
        e.stopPropagation();
        if (code === webhookCode || code === jiraCode) {
            dispatch(
                setSideBarObject({
                    name,
                    logo,
                    code,
                    category,
                    is_installed: true,
                })
            );
            return;
        }
        setSyncLoading(true);
        try {
            const res = await Api.commonApi.syncPlugin(code);
            if (res.message) {
                Toaster.success(res.message);
            }
            setSyncLoading(false);
            if (res.url) {
                window.location.href = res.url;
                return;
            }
            await updatePluginList();
        } catch (err: any) {
            setSyncLoading(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    return (
        <div
            className={`relative w-full h-full rounded-md py-6 px-10 cursor-pointer border-2 ${
                hover
                    ? 'shadow-[0_4px_15px_0_#1780e036] border-white'
                    : 'border-zinc-100'
            }`}
            onMouseEnter={() => {
                !installed && setHover(true);
            }}
            onMouseLeave={() => setHover(false)}
            aria-hidden={installed ? false : true}
        >
            <img
                className='w-12 h-12'
                src={hover ? Icons['addPlusAqua'] : logo}
                alt='Add Logo'
                aria-hidden
            />
            <span
                className={`flex justify-center absolute text-xs text-center bottom-3 left-0 my-auto w-full tracking-[.125em] uppercase ${
                    installed && 'text-red-700'
                }`}
                onClick={handleRemove}
                title={installed ? 'Remove ' + name : ''}
                role='button'
            >
                {removeLoading && (
                    <img
                        src={Icons.redLoaderGif}
                        alt='Loading'
                        className='inline-block mr-1 w-4 h-4'
                    />
                )}
                {installed ? 'Remove' : name}
            </span>
            {installed && (
                <div className='absolute flex flex-col space-y-1.5 right-2.5 top-2.5 w-[18px]'>
                    <img
                        src={Icons.checkIcon}
                        alt='Check Icon'
                        role='presentation'
                    />
                    <img
                        style={{
                            animation: syncLoading
                                ? 'rotate infinite 2s linear'
                                : '',
                        }}
                        onClick={handleSync}
                        src={Icons.refreshIcon}
                        alt={'Sync ' + name}
                        role='button'
                    />
                </div>
            )}
        </div>
    );
}
