import { createSlice } from '@reduxjs/toolkit';
import {
    globalList,
    IList,
    IListItem,
    IPreferences,
} from '../../assets/constants/constants';

interface IInitialState {
    sideBarObject: IListItem | {};
    instructionState: string;
    plugins: IList;
    preferences: IPreferences;
    googleChatOptions: {
        option: string;
        value: string;
    }[] | null;
    webexOptions: {
        option: string;
        value: string;
    }[] | null;
    zohoCliqOptions: {
        option: string;
        value: string;
    }[] | null;
    azureDevOpsOptions: {
        option: string;
        value: string;
    }[] | null;
    jiraOptions: {
        name: string;
        id: string;
        url: string;
    }[] | null;
    showSettings: boolean;
    accessibilityEnabled: boolean;
}

const initialState: IInitialState = {
    sideBarObject: {},
    instructionState: 'Instructions',
    plugins: globalList,
    preferences: {
        screenshotTestCompletion: true,
        buildCompletion: true,
        testFail: true,
        DailyTestReportAutomation: true,
        buildNotificationTime: 15,
        testPass: false,
    },
    googleChatOptions: null,
    webexOptions: null,
    zohoCliqOptions: null,
    azureDevOpsOptions: null,
    jiraOptions: null,
    showSettings: false,
    accessibilityEnabled: false,
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setSideBarObject: (state, action) => {
            if (Object.keys(action.payload).length > 0) {
                window.history.pushState(
                    {},
                    '',
                    `/${action.payload.code}/install`
                );
                document.title = `${action.payload.name} - LambdaTest`;
            } else {
                window.history.pushState({}, '', '/');
                document.title = 'Integrations - LambdaTest';
            }
            state.sideBarObject = action.payload;
        },
        setInstructionState: (state, action) => {
            state.instructionState = action.payload;
        },
        setPlugins: (state, action) => {
            state.plugins = action.payload;
        },
        setPreferences: (state, action) => {
            state.preferences = action.payload;
        },
        setGoogleChatOptions: (state, action) => {
            state.googleChatOptions = action.payload;
        },
        setWebexOptions: (state, action) => {
            state.webexOptions = action.payload;
        },
        setZohoCliqOptions: (state, action) => {
            state.zohoCliqOptions = action.payload;
        },
        setAzureDevOpsOptions: (state, action) => {
            state.azureDevOpsOptions = action.payload;
        },
        setJiraOptions: (state, action) => {
            state.jiraOptions = action.payload;
        },
        setShowSettings: (state, action) => {
            state.showSettings = action.payload;
        },
        setAccessibilityEnabled: (state, action) => {
            state.accessibilityEnabled = action.payload;
        },
    },
});

export const {
    setSideBarObject,
    setInstructionState,
    setPlugins,
    setPreferences,
    setGoogleChatOptions,
    setWebexOptions,
    setZohoCliqOptions,
    setAzureDevOpsOptions,
    setJiraOptions,
    setShowSettings,
    setAccessibilityEnabled
} = configSlice.actions;

export default configSlice.reducer;
