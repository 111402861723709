import { useEffect, useRef, useState } from 'react';
import Api from '../utils/api';
import { updatePluginList } from '../utils/helper';
import Toaster from './Toaster/Index';
import { IListItem, jiraCode, webhookCode, copilotCode } from '../assets/constants/constants';
import { useDispatch } from 'react-redux';
import {
    setShowSettings,
    setSideBarObject,
} from '../redux/features/configSlice';
import { ActionList, Box, Button, Octicon, Spinner, Text } from '@primer/react';
import { CheckIcon, KebabHorizontalIcon } from '@primer/octicons-react';
import { LTActionListV2, LTAnchoredOverlay, LTBox, LTButton, LTIconButton, LTText } from '@lambdatestincprivate/lt-components';

function useOutsideAlerter(
    ref: any,
    setShowSettings: React.Dispatch<React.SetStateAction<boolean>>
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowSettings(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setShowSettings]);
}

export function Card({
    element,
    installPlugin,
}: {
    element: IListItem;
    installPlugin: (element: IListItem) => Promise<void>;
}) {
    const { logo, name, code, category } = element;
    const installed = element.is_installed;
    const [syncLoading, setSyncLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [anchorOpen, setAnchorOpen] = useState(false);
    const wrapperRef: any = useRef(null);
    useOutsideAlerter(wrapperRef, setAnchorOpen);

    const dispatch = useDispatch();

    const handleRemove = async () => {
        if (installed === false) {
            return;
        }
        setRemoveLoading(true);
        try {
            const res = await Api.commonApi.removePlugin(code);
            if (res.message) {
                Toaster.success(res.message);
            }
            setRemoveLoading(false);
            setAnchorOpen(false);
			closeAnchor();
            await updatePluginList();
        } catch (err: any) {
            setRemoveLoading(false);
            if (err?.response?.data?.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const handleSync = async () => {
        if (installed === false) {
            return;
        }
        if (code === webhookCode || code === jiraCode) {
            dispatch(
                setSideBarObject({
                    name,
                    logo,
                    code,
                    category,
                    is_installed: true,
                })
            );
            return;
        }
        setSyncLoading(true);
        try {
            const res = await Api.commonApi.syncPlugin(code);
            if (res.message) {
                Toaster.success(res.message);
            }
            setSyncLoading(false);
            if (res.url) {
                window.location.href = res.url;
                return;
            }
            await updatePluginList();
        } catch (err: any) {
            setSyncLoading(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

	const handleKeyDown = (event: any) => {
		if (event.key === 'Escape') {
			setAnchorOpen(false);
		}
	};

	const closeAnchor = () => {
		var actionMenu = document.getElementById('action-menu');
		if (actionMenu) {
			actionMenu.style.display = 'none';
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
	
		// Clean up the event listener on component unmount
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

    return (
        <LTBox
            sx={{
                borderRadius: 6,
                display: 'inline-block',
                padding: '12px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'border.default',
                fontSize: 12,
            }}
        >
            <LTBox
                sx={{
                    display: 'flex',
                    gap: '8px',
                    height: '100%',
                }}
            >
                <img
                    className='w-7 h-7'
                    src={logo}
                    alt='Add Logo'
                    aria-hidden
                />
                <LTBox
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <LTBox>
                        <LTBox
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: '4px',
                            }}
                        >
                            <LTText
                                as='h2'
                                fontSize={14}
                                margin={0}
                            >
                                {name}
                            </LTText>
                            {installed && (
                                <LTBox
                                    color={'success.fg'}
                                    padding={0}
                                    sx={{ display: 'flex' }}
                                >
                                    <CheckIcon size={16} />
                                    <LTText> &nbsp;Connected</LTText>
                                </LTBox>
                            )}
                        </LTBox>
                        <LTText
                            color={'muted'}
                            lineHeight={'16px'}
                        >
                            {element.description}
                        </LTText>
                    </LTBox>
                    <LTBox sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                        <LTButton
							ariaLabel={installed
								? code === webhookCode
									? 'Update'
									: 'Re-sync'
								: 'Connect'}
                            onClick={() => {
                                installed
                                    ? handleSync()
                                    : installPlugin(element);
                            }}
                            variant={LTButton.Variant.DEFAULT}
                        >
                            {syncLoading ? (
                                <Spinner size={'small'} />
                            ) : (
                                <>
                                    {installed
                                        ? code === webhookCode
                                            ? 'Update'
                                            : 'Re-sync'
                                        : 'Connect'}
                                </>
                            )}
                        </LTButton>
                        {installed && (
                            <div>
								<LTAnchoredOverlay
									ariaLabel='More Options'
									buttonVariant={LTIconButton.IconButtonVariants.INVISIBLE}
									iconComponent={KebabHorizontalIcon}
									side={LTAnchoredOverlay.Side.OUTSIDE_BOTTOM}
									closeOverlay={!anchorOpen}
									onBackdropClick={() => setAnchorOpen(true)}
								>
									<div id='action-menu'>
									<LTActionListV2
										list={[
											...((category === 'COMMUNICATION' && code !== copilotCode)
											? [{
												label: "Settings",
												value: "settings",
												onSelect: () => {
													setAnchorOpen(false);
													dispatch(setSideBarObject(element));
													dispatch(setShowSettings(true));
													closeAnchor();
												}
												}]
											: []),
											{
												label: removeLoading ? "Removing.." : "Remove",
												trailingVisual: removeLoading ? <Spinner size='small' /> : null,
												value: "remove",
												onSelect: handleRemove
											}
										]}
									/>
									</div>
								</LTAnchoredOverlay>
                            </div>
                        )}
                    </LTBox>
                </LTBox>
            </LTBox>
        </LTBox>
    );
}
