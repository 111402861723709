// @ts-nocheck
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Home';
import ErrorPage from './ErrorPage';
import { oauthCallbackRoutes, routeCodes } from '../assets/constants/constants';

import { LTToast } from '@lambdatestincprivate/lt-components';
import { useEffect } from 'react';
import { theme } from '@primer/react';
import deepmerge from 'deepmerge';
import LTCommonHeader from '@lambdatestincprivate/lt-common-header';

const redirectUrl = `${
    process.env.REACT_APP_ACCOUNTS_URL || 'https://accounts.lambdatest.com'
}/logout`;

const Redirect = () => {
    window.location.href = redirectUrl;
    return <></>;
};

function App() {
    const accessibilityEnabled = useSelector(
        (state: RootState) => state.config.accessibilityEnabled
    );

    useEffect(() => {
        window.toast.extendDuration = accessibilityEnabled;
    }, [accessibilityEnabled]);

	useEffect(() => {
		// Function to add role="img" to all SVG elements
		function addRoleToSVGs() {
			document.querySelectorAll('svg').forEach(function(svg) {
				svg.setAttribute('role', 'img');
			});
		}

		// Initial call to set role on all existing SVGs
		addRoleToSVGs();

		// Create a MutationObserver to watch for new SVG elements being added
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				mutation.addedNodes.forEach((node) => {
					if (node.nodeName.toLowerCase() === 'svg') {
						node.setAttribute('role', 'img');
					} else if (node.querySelectorAll) {
						node.querySelectorAll('svg').forEach((svg) => {
							svg.setAttribute('role', 'img');
						});
					}
				});
			});
		});

		// Start observing the document for changes
		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	}, []);

    const sendNotification = (addToast: any) => {
      window.toast = {
        addToast,
        extendDuration: accessibilityEnabled,
        toastVariants: LTToast.ToastVariants,
      };
    };

    return (
            <Router>
                <Routes>
                    <Route
                        path='/'
                        element={<HandleCommonHeader />}
                    />
                    {routeCodes.map((route) => (
                        <Route
                            path={`/${route}/install`}
                            element={<HandleCommonHeader />}
                        />
                    ))}
                    {oauthCallbackRoutes.map((route) => (
                        <Route
                            path={`/${route}/auth/callback`}
                            element={<HandleCommonHeader />}
                        />
                    ))}
                    <Route
                        path='/logout'
                        element={<Redirect />}
                    />
                    <Route
                        path='*'
                        element={<ErrorPage />}
                    />
                </Routes>
                <LTToast sendNotification={sendNotification} />
            </Router>
    );
}

function HandleCommonHeader() {
    const location = useLocation();

	const accessibilityEnabled = useSelector(
        (state: RootState) => state.config.accessibilityEnabled
    );

	useEffect(() => {
		if(!accessibilityEnabled) {
			const announcekitScript = document.getElementById("lt-announcekit");
			if (announcekitScript) {
				return;
			}
			insertAnnouncekit();
		}
		return () => {
			removeAnnouncekit();
		};
	}, [accessibilityEnabled]);
    
    useEffect(() => {
        const content = document.getElementById('left_sidebar_header');
        if (content) content!.style!.justifyContent = 'space-between';
        
        const searchParams = new URLSearchParams(location.search);
        const redirectTo = searchParams.get('redirectTo');
        const expiryTime = new Date();
        expiryTime.setMinutes(expiryTime.getMinutes() + 10);

        // Save redirectTo value in a cookie with expiry time
        if (redirectTo && redirectTo !== '') {
            document.cookie = `redirectTo=${redirectTo}; expires=${expiryTime.toUTCString()}; path=/;`;
        }
    }, []);

	const insertAnnouncekit = () => {
		const script = document.createElement("script");
		script.src = "//cdn.announcekit.app/widget.js";
		script.id = "lt-announcekit";
		script.type = "text/javascript";
		script.async = true;
		document.head.appendChild(script);
	};
	
	const removeAnnouncekit = () => {
		const scriptElement = document.getElementById("lt-announcekit");
		if (scriptElement) {
			scriptElement.remove();
		}
	};

    return (
        <LTCommonHeader
            showTunnel
            isNewThemeEnabled={true}
            customProductHeader={
				<h1 className='productTitle'>Integrations</h1>
			}
			isHighContrastThemeEnabled={accessibilityEnabled}
        >
            <Home />
        </LTCommonHeader>
    );
}

export default App;
