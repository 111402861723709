import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { setGoogleChatOptions } from '../../../redux/features/configSlice';
// import { Select } from '@primer/react';
import { customStyles } from '../../../assets/constants/constants';
import { EventNames } from '../../../utils/events';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
} from '@primer/react';
import { LTButton, LTFormControl, LTSelect } from '@lambdatestincprivate/lt-components';

export default function GoogleChat() {
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const googleChatOptions = useSelector(
        (state: RootState) => state.config.googleChatOptions
    );
    const [isLoading, setIsLoading] = useState(false);
    const [space, setSpace] = useState('');
    const [spaceName, setSpaceName] = useState('');
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (space.length === 0) {
            return;
        }
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                space,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
            dispatch(setGoogleChatOptions(null));
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <LTFormControl
                    label='Select a space'
                    labelStyle={{
                        fontSize: 12,
                    }}
                    formStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    {googleChatOptions && (
                        <LTSelect
                            options={googleChatOptions.map((elem) => ({
                                name: elem.option,
                                value: elem.value,
                            }))}
                            onChange={(values :any) => {
                                console.log("valuesss", values);
                                setSpace(values[0].value);
                                setSpaceName(values[0].name);
                            }}
                            customButtonText={spaceName ?? 'Select Space'}
                            buttonStyle={{
                                width: '100%',
                                maxWidth: '100%',
                                '[data-component="buttonContent"]': {
                                justifyContent: 'start',
                                },
                            }}
                        />
                    )}
                    {(errors as any).space && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).space && (errors as any).space[0]}
                        </small>
                    )}
                </LTFormControl>
                <LTButton
                    variant={LTButton.Variant.PRIMARY}
                    disabled={isLoading}
                    onClick={install}
                    className='self-start'
                >
                    Install
                </LTButton>
            </form>
        </LeftPanel>
    );
}
