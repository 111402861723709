import { useState } from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { EventNames } from '../../../utils/events';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
} from '@primer/react';
// import { Select } from '@primer/react';
import { customStyles } from '../../../assets/constants/constants';
import { LTButton, LTFormControl, LTSelect, LTText } from '@lambdatestincprivate/lt-components';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

const newRelicOptions = [
    {
        label: 'US',
        value: 'US',
    },
    {
        label: 'EU',
        value: 'EU',
    },
    {
        label: 'FedRAMP',
        value: 'FedRAMP',
    },
];

export default function NewRelic() {
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [isLoading, setIsLoading] = useState(false);
    const [licenseKey, setLicenseKey] = useState('');
    const [region, setRegion] = useState('');
    const [regionName, setRegionName] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                licenseKey,
                region,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            await updatePluginList();
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <LTFormControl
                    label='License Key/API key'
                    labelStyle={{
                        fontSize: 12,
                    }}
                    formStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <div className='flex border border-gray-200 rounded-sm'>
                        <LTTextInput
                            inputOnly={true}
                            type='text'
                            id='licenseKey'
                            aria-label='Api key'
                            width={'100%'}
                            contrast
                            placeholder=''
                            value={licenseKey}
                            onChange={(value :any) => setLicenseKey(value)}
                            required
                        />
                    </div>
                    {(errors as any).licenseKey && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).licenseKey &&
                                (errors as any).licenseKey[0]}
                        </small>
                    )}
                </LTFormControl>
                <LTFormControl
                    label='Region (US, EU, FedRAMP)'
                    labelStyle={{
                        fontSize: 12,
                    }}
                    formStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <LTSelect
                        options={newRelicOptions.map((elem) => ({
                            name: elem.label,
                            value: elem.value,
                        }))}
                        onChange={(values :any) => {
                            setRegion(values[0].value);
                            setRegionName(values[0].name);
                        }}
                        customButtonText={regionName ?? 'Select Region'}
                        buttonStyle={{
                            width: '100%',
                            maxWidth: '100%',
                            '[data-component="buttonContent"]': {
                            justifyContent: 'start',
                            },
                        }}
                    />
                    {(errors as any).region && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).region &&
                                (errors as any).region[0]}
                        </small>
                    )}
                </LTFormControl>
                <LTButton
                    variant={LTButton.Variant.PRIMARY}
                    disabled={isLoading}
                    onClick={install}
                    className='self-start'
                >
                    Install
                </LTButton>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'muted'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/new-relic-integration/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Personal Access Token
                </Link>
            </div>
        </LeftPanel>
    );
}
