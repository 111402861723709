import { Label, LeftPanel } from '../../Wrappers';

export default function Shopify() {
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
            >
                <div className='flex flex-col space-y-2'>
                    <Label>
                        Enter your shop domain to login and install this app
                    </Label>
                    <div className='flex border-1  border-gray-300 rounded-sm'>
                        <input
                            type='text'
                            id='url'
                            aria-label='Url'
                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                            placeholder='shopname.myshopify.com'
                        />
                    </div>
                </div>
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                >
                    Install
                </button>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'>
                <Label>Know how to generate</Label>
                <a
                    href='https://www.lambdatest.com/support/docs/shopify-integration'
                    target='_blank'
                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                    rel='noreferrer'
                >
                    Webhook URL
                </a>
            </div>
        </LeftPanel>
    );
}
