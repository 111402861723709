import { useEffect, useState } from 'react';
import Icons from '../../../assets/images';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import { updatePluginList } from '../../../utils/helper';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
} from '@primer/react';
import { LTButton, LTFormControl, LTText } from '@lambdatestincprivate/lt-components';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

interface IProjectList {
    id: number;
    name: string;
    identifier: string;
    description: string;
    status: number;
    is_public: boolean;
    inherit_members: boolean;
    created_on: string;
    updated_on: string;
    parent?: undefined | {};
}

export default function Redmine() {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [host, setHost] = useState('');
    const [token, setToken] = useState('');
    const [selected_projects, setSelected_projects] = useState<number[]>([]);
    const [list, setList] = useState<IProjectList[]>([]);
    const [search, setSearch] = useState('');
    const [projectList, setProjectList] = useState<IProjectList[]>([]);
    const [projectPages, setProjectPages] = useState(0);
    const [currentProjectPage, setCurrentPage] = useState(0);
    const [tempList, setTempList] = useState<IProjectList[]>([]);

    useEffect(() => {
        setTempList(list);
        if (list.length > 10) {
            setProjectList(list.slice(0, 10));
            if (list.length % 10 === 0) {
                setProjectPages(Math.floor(list.length / 10));
            } else {
                setProjectPages(Math.floor(list.length / 10) + 1);
            }
        } else {
            setProjectList(list.slice(0, 10));
        }
        setCurrentPage(0);
    }, [list]);
    const handleSubmit = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        try {
            const res = await Api.installApi.redmineInstall({
                host,
                token,
                is_projects: false,
                selected_projects: [],
            });
            setList(res);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const installProject = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (selected_projects.length === 0) {
            Toaster.error('Please select at least one project to integrate.');
            return;
        }
        setIsLoading(true);
        try {
            await Api.installApi.redmineInstall({
                host,
                token,
                is_projects: true,
                selected_projects,
            });
            setIsLoading(false);
            Toaster.success('Projects installed successfully');
            await updatePluginList();
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const inputSearch = (value :string) => {
        setSearch(value);
        const searchedList = list.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase())
        );
        setTempList(searchedList);
        if (searchedList.length > 10) {
            setProjectList(searchedList.slice(0, 10));
            if (searchedList.length % 10 === 0) {
                setProjectPages(Math.floor(searchedList.length / 10));
            } else {
                setProjectPages(Math.floor(searchedList.length / 10) + 1);
            }
        } else {
            setProjectList(searchedList);
        }
        setCurrentPage(0);
    };

    const increement = () => {
        if (currentProjectPage + 1 >= projectPages) {
            return;
        }
        const newCurrentPage = currentProjectPage + 1;
        setCurrentPage(newCurrentPage);
        setProjectList(
            tempList.slice(
                newCurrentPage * 10,
                Math.min(tempList.length, (newCurrentPage + 1) * 10)
            )
        );
    };

    const decreement = () => {
        if (currentProjectPage - 1 < 0) {
            return;
        }
        const newCurrentPage = currentProjectPage - 1;
        setCurrentPage(newCurrentPage);
        setProjectList(
            tempList.slice(
                newCurrentPage * 10,
                Math.min(tempList.length, (newCurrentPage + 1) * 10)
            )
        );
    };

    const checkProject = (checked: boolean, id: number) => {
        if (checked === false) {
            setSelected_projects(
                selected_projects.filter((elem) => elem !== id)
            );
        } else {
            setSelected_projects([...selected_projects, id]);
        }
    };

    const checkAll = (e: React.ChangeEvent<any>) => {
        const projects: number[] = [];
        if (e.target.checked) {
            list.forEach((elem) => projects.push(elem.id));
            setSelected_projects(projects);
        } else {
            setSelected_projects([]);
        }
    };

    return (
        <LeftPanel>
            {list.length === 0 && (
                <form
                    onSubmit={handleSubmit}
                    className='flex flex-col space-y-4'
                    id='form1'
                >
                    <LTFormControl
                        label='Domain'
                        labelStyle={{
                            fontSize: 12,
                        }}
                        formStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <LTTextInput
                            inputOnly={true}
                            type='text'
                            aria-label='Domain'
                            onChange={(value :any) => {
                                setHost(value);
                            }}
                            value={host}
                            width={'100%'}
                            contrast
                            placeholder='e.g., https://lambdatest.redmine.com'
                            required
                        />

                        {(errors as any).host && (
                            <small className='w-full h-2.5 text-[10px] text-red-600'>
                                {(errors as any).host &&
                                    (errors as any).host[0]}
                            </small>
                        )}
                    </LTFormControl>
                    <LTFormControl
                        label='API Token'
                        labelStyle={{
                            fontSize: 12,
                        }}
                        formStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <LTTextInput
                            inputOnly={true}
                            type='password'
                            aria-label='API Token'
                            onChange={(value :any) => {
                                setToken(value);
                            }}
                            value={token}
                            width={'100%'}
                            contrast
                            placeholder=''
                            required
                        />

                        {(errors as any).token && (
                            <small className='w-full h-2.5 text-[10px] text-red-600'>
                                {(errors as any).token &&
                                    (errors as any).token[0]}
                            </small>
                        )}
                    </LTFormControl>
                    <LTButton
                        variant={LTButton.Variant.PRIMARY}
                        disabled={isLoading}
                        onClick={handleSubmit}
                        className='self-start'
                    >
                        Next
                    </LTButton>
                </form>
            )}
            {list.length > 0 && (
                <form
                    onSubmit={installProject}
                    className='flex flex-col space-y-4'
                    id='form2'
                >
                    <div className='border-y-[1px] border-gray-300 text-sm py-2.5 flex justify-between'>
                        <div>Select Projects</div>
                        <div className='flex'>
                            <div>{`${selected_projects.length}  /${list.length}`}</div>
                            <div className='ml-3 opacity-60'>Selected</div>
                        </div>
                    </div>
                    <div className='text-sm'>
                        <div className='flex p-2.5 bg-zinc-50'>
                            <input
                                type={'checkbox'}
                                className='mr-2.5'
                                onChange={checkAll}
                                checked={
                                    selected_projects.length === list.length
                                }
                            />
                            <LTTextInput
                                type='text'
                                aria-label='Search Project'
                                onChange={inputSearch}
                                value={search}
                                contrast
                                className='placeholder-stone-500 w-full focus:outline-none focus:border-px h-8 px-2'
                                placeholder='Search'
                            />
                        </div>
                        {projectList.map((element, index) => {
                            return (
                                <div
                                    className={`flex p-2.5 ${
                                        index % 2 !== 0 && 'bg-zinc-50'
                                    }`}
                                >
                                    <input
                                        type={'checkbox'}
                                        className='mr-2.5'
                                        onChange={(e) =>
                                            checkProject(
                                                e.target.checked,
                                                element.id
                                            )
                                        }
                                        checked={selected_projects.includes(
                                            element.id
                                        )}
                                    />
                                    {element.name}
                                </div>
                            );
                        })}
                    </div>
                    {projectPages > 0 && (
                        <div className='text-neutral-600 flex'>
                            Showing{' '}
                            {Math.min(
                                tempList.length,
                                currentProjectPage * 10 + 1
                            )}{' '}
                            -{' '}
                            {Math.min(
                                tempList.length,
                                (currentProjectPage + 1) * 10
                            )}{' '}
                            of {tempList.length}
                            <div
                                className='cursor-pointer ml-3 mr-1'
                                onClick={decreement}
                            >
                                {'<'}
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={increement}
                            >
                                {'>'}
                            </div>
                        </div>
                    )}
                    <LTButton
                        variant={LTButton.Variant.PRIMARY}
                        disabled={isLoading}
                        onClick={installProject}
                        className='self-start'
                    >
                        Integrate Projects & Install
                    </LTButton>
                </form>
            )}
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'muted'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/redmine-integration/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Personal Access Token
                </Link>
            </div>
        </LeftPanel>
    );
}
