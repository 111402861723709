import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LeftPanel } from '../../Wrappers';
import { setInstructionState } from '../../../redux/features/configSlice';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
// import { Select } from '@primer/react';
import { getCookie, sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { EventNames } from '../../../utils/events';
import {
    Box,
    // Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
    Token,
    Radio,
    Pagination,
    RadioGroup,
} from '@primer/react';
import { DataTable, Table } from '@primer/react/drafts';
import { SearchIcon } from '@primer/octicons-react';
import { LTBox, LTButton, LTCheckbox, LTFormControl, LTPagination, LTRadio, LTSelect, LTText } from '@lambdatestincprivate/lt-components';
import { url } from 'inspector';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

interface IProject {
    id: string;
    key: string;
    name: string;
}

export default function Jira() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('Cloud');
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const jiraOptions = useSelector(
        (state: RootState) => state.config.jiraOptions
    );
    const [isLoading, setIsLoading] = useState(false);
    const [host, setHost] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [username, setUsername] = useState('');
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({});
    const [projectsList, setProjectsList] = useState<IProject[]>([]);
    const [site, setSite] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [siteName, setSiteName] = useState('');
    const [tempProjectList, setTempProjectList] = useState<IProject[]>([]);
    const [selectedProjects, setSelectedProjects] = useState<IProject[]>([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [projectLoading, setProjectLoading] = useState(false);
    const [lastSearched, setLastSearched] = useState('');
    const [searchedCount, setSearchedCount] = useState(0);

    useEffect(() => {
        if(jiraOptions && jiraOptions.length > 0) {
            setSite(jiraOptions[0].id);
            setSiteUrl(jiraOptions[0].url);
            setSiteName(jiraOptions[0].name);
        }
    }, [jiraOptions]);

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            if (instanceType === 'Cloud') {
                const res = await Api.installApi.getOAuthURL(sideBarObject.code);
                if (res.url) {
                    window.open(res.url, '_self');
                    setIsLoading(false);
                    return;
                }
            }
            else if (instanceType === 'SelfHosted' && /\s/.test(host)) {
                setErrors({ host: ['Please enter a valid URL'] });
                setIsLoading(false);
                return;
            }
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                host,
                subdomain,
                instance_type: instanceType,
                username: username,
                token: token,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            if (res.projects && res.projects.length > 0) {
                setProjectsList(res.projects);
                if (instanceType === 'Cloud') {
                    setTotalProjects(res.total);
                    setCurrentPage(1);
                    setSearchedCount(res.total);
                } else {
                    setTempProjectList(res.projects);
                    setTotalProjects(res.projects.length);
                }
            }
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const siteInstall = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if(site) {
            try {
                setIsLoading(true);
                const res = await Api.installApi.siteInstall(sideBarObject.code, {
                    site: site,
                    siteUrl: siteUrl
                });
                if (res.message) {
                    Toaster.success(res.message);
                }
                if (res.projects && res.projects.length > 0) {
                    setProjectsList(res.projects);
                    setTotalProjects(res.total);
                    setCurrentPage(1);
                    setSearchedCount(res.total);
                }
                setIsLoading(false);
            } catch (err: any) {
                if (err.response.data.message) {
                    Toaster.error(err.response.data.message);
                }
                setIsLoading(false);
            }
        }
    }

    const projectInstall = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (selectedProjects.length < 1) {
            return;
        }
        try {
            setIsLoading(true);
            const res = await Api.installApi.apiInstall(sideBarObject.code, {
                selected_projects: selectedProjects.map((x) => x.key),
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            await updatePluginList();
            
            const redirectToValue = getCookie('redirectTo');
            if (redirectToValue && redirectToValue !== '') {
                const url = decodeURIComponent(redirectToValue); 
                window.location.href = url;
            }
        } catch (err: any) {
            console.log('errorrr', err.message)
            Toaster.error('Something went wrong');
            // if (err.response?.data?.message) {
            //     Toaster.error(err.response.data.message);
            // }
            setIsLoading(false);
        }
    };

    const paginate = async (page: number) => {
        if (page > Math.ceil(searchedCount / 50)) {
            return;
        }
        const newCurrentPage = page;
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                page: newCurrentPage,
                ...(lastSearched.length > 0 && { search: lastSearched }),
            });
            setCurrentPage(newCurrentPage);
            setProjectLoading(false);
            setProjectsList(res.projects);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const increement = async () => {
        if (currentPage + 1 > Math.ceil(searchedCount / 50)) {
            return;
        }
        const newCurrentPage = currentPage + 1;
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                page: newCurrentPage,
                ...(lastSearched.length > 0 && { search: lastSearched }),
            });
            setCurrentPage(newCurrentPage);
            setProjectLoading(false);
            setProjectsList(res.projects);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const decreement = async () => {
        if (currentPage - 1 <= 0) {
            return;
        }
        const newCurrentPage = currentPage - 1;
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                page: newCurrentPage,
                ...(lastSearched.length > 0 && { search: lastSearched }),
            });
            setCurrentPage(newCurrentPage);
            setProjectLoading(false);
            setProjectsList(res.projects);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const checkProject = (checked: boolean, element: IProject) => {
        if (checked === false) {
            setSelectedProjects(
                selectedProjects.filter((elem) => elem.id !== element.id)
            );
        } else {
            setSelectedProjects([...selectedProjects, element]);
        }
    };

    const searchProject = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (search.length === 0) {
            return 0;
        }
        if (instanceType === 'SelfHosted') {
            setProjectsList(
                tempProjectList.filter(
                    (e) =>
                        e.name.toLowerCase().indexOf(search.toLowerCase()) > 0
                )
            );
            setLastSearched(search);
            return;
        }
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                search,
            });
            if (res.projects && res.total > 0) {
                setProjectsList(res.projects);
                setSearchedCount(res.total);
                setCurrentPage(1);
                setLastSearched(search);
            }
            if (res.type === 'error' && res.message) {
                Toaster.error(res.message);
            }
            setProjectLoading(false);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
            setProjectLoading(false);
        }
    };

    const searchClear = async () => {
        setSearch('');
        setLastSearched('');
        if (instanceType === 'SelfHosted') {
            setProjectsList([...tempProjectList]);
            return;
        }
        setProjectLoading(true);
        const res = await Api.installApi.commonInstall(sideBarObject.code, {
            page: 1,
        });
        if (res.projects && res.projects.length > 0) {
            setProjectsList(res.projects);
            setSearchedCount(res.total);
            setTotalProjects(res.total);
            setCurrentPage(1);
        }
        setProjectLoading(false);
    };

    useEffect(() => {
        if (sideBarObject.is_installed) {
            searchClear();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <LeftPanel>
            {jiraOptions && jiraOptions.length > 0 && totalProjects === 0 ? (
                <LTFormControl
                    formStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                    label='Select your Site'
                    labelStyle={{
                        fontSize: 12,
                    }}
                >
                    <LTSelect
                        options={jiraOptions.map((elem) => ({
                            name: elem.name,
                            value: elem.id,
                            url: elem.url,
                        }))}
                        onChange={(values :any) => {
                            console.log("valuesss", values);
                            setSite(values[0].value);
                            setSiteUrl(values[0].url);
                            setSiteName(values[0].name);
                        }}
                        customButtonText={siteName ?? 'Select Site'}
                        buttonStyle={{
                            width: '100%',
                            maxWidth: '100%',
                            '[data-component="buttonContent"]': {
                              justifyContent: 'start',
                            },
                        }}
                        leadingText=""
                    />
                    <LTBox>
                        <LTButton
                            onClick={siteInstall}
                            disabled={isLoading}
                            variant={LTButton.Variant.PRIMARY}
                        >
                            Select Site & Proceed
                        </LTButton>
                    </LTBox>
                </LTFormControl>
            ) : totalProjects > 0 || sideBarObject.is_installed ? (
                <div
                    className={`flex flex-col space-y-2 h-full min-h-[590px] ${
                        projectLoading ? 'blur-sm pointer-events-none' : ''
                    }`}
                >
                    {/* <div className='border-y-[1px] border-gray-300 text-sm py-2.5 flex justify-between'>
                        <div>Select Projects</div>
                        <div className='flex'>
                            <div>{`${selectedProjects.length}  /${totalProjects}`}</div>
                            <div className='ml-3 opacity-60'>Selected</div>
                        </div>
                    </div> */}
                    <LTText
                        as='h6'
                        fontSize={14}
                        text='Select Projects'
                    />
                    {/* {selectedProjects.length > 0 && (
                        <div>
                            {selectedProjects.map((project) => (
                                <div className='text-gray-800 text-xs font-medium mr-1 mb-1 px-1.5 py-0.5 rounded border-1  border-gray-500 inline-flex'>
                                    {project.name}
                                    <img
                                        src={Icons.closeIcon}
                                        alt='close'
                                        className='w-2 ml-0.5 cursor-pointer'
                                        onClick={() =>
                                            checkProject(false, project)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )} */}
                    <div className='flex justify-between space-x-2'>
                        <form
                            id='form2'
                            onSubmit={searchProject}
                            className='w-full'
                        >
                            <LTTextInput
                                type='text'
                                contrast
                                aria-label='Search Project'
                                onChange={(value :any) => setSearch(value)}
                                value={search}
                                placeholder='Press Enter to Search'
                                // leadingVisual={<SearchIcon />}
                                width={'100%'}
                                allowClearSearch={false}
                            />
                        </form>
                        {lastSearched.length > 0 && (
                            <LTButton onClick={searchClear} variant={LTButton.Variant.DEFAULT}>
                                Clear
                            </LTButton>
                        )}
                    </div>
                    {selectedProjects.length > 0 && (
                        <div>
                            {selectedProjects.map((project) => (
                                <Token
                                    text={project.name}
                                    sx={{
                                        mb: '6px',
                                        mr: '8px',
                                        fontWeight: 600,
                                        color: 'token.fg',
                                        bg: 'token.bg',
                                        borderColor: 'border.muted',
                                        padding: '0px 0px 0px 8px',
                                    }}
                                    onRemove={() =>
                                        checkProject(false, project)
                                    }
                                    size='small'
                                ></Token>
                            ))}
                        </div>
                    )}
                    <LTBox styles={{ overflowY: 'scroll', minHeight: '100px' }}>
                        <Table.Container>
                            <DataTable
                                aria-labelledby='projects'
                                aria-describedby='projects'
                                data={projectsList}
                                columns={[
                                    {
                                        id: '1',
                                        header: `${totalProjects} Projects`,
                                        renderCell: (row) => {
                                            return (
                                                // <LTFormControl>
                                                    <LTCheckbox
                                                        checked={selectedProjects.some(
                                                            (p) =>
                                                                p.id === row.id
                                                        )}
                                                        onChange={(value :any) => {
                                                            console.log("checked valueee", value)
                                                            console.log("rowww", row)
                                                            checkProject(
                                                                value,
                                                                row
                                                            )
                                                        }}
                                                        label={row.name}
                                                        labelStyle={{
                                                            paddingTop: '2px',
                                                            fontWeight: 500,
                                                        }}
                                                    />
                                                //     {/* <FormControl.Label
                                                //         sx={{
                                                //             paddingTop: '2px',
                                                //             fontWeight: 500,
                                                //         }}
                                                //     >
                                                //         {row.name}
                                                //     </FormControl.Label> */}
                                                // </LTFormControl>
                                            );
                                        },
                                    },
                                ]}
                            ></DataTable>
                        </Table.Container>
                    </LTBox>
                    {searchedCount > 0 && (
                        <LTBox
                            padding={'8px 0px'}
                            className='paginationContainer'
                            styles={{
                                border: '1px',
                                borderStyle: 'solid',
                                borderColor: 'border.default',
                            }}
                        >
                            <LTPagination
                                pageCount={Math.ceil(searchedCount / 50)}
                                currentPage={currentPage}
                                surroundingPageCount={1}
                                onChange={(e: React.ChangeEvent<any>) => {
                                    const hash =
                                        e.currentTarget.getAttribute('href') ||
                                        '';
                                    if (hash.length > 0) {
                                        const pageIndex = parseInt(
                                            hash.substring(1)
                                        );
                                        console.log(pageIndex);
                                        paginate(pageIndex);
                                    }
                                }}
                            />
                        </LTBox>
                    )}
                    <LTBox>
                        <LTButton
                            onClick={projectInstall}
                            disabled={isLoading || selectedProjects.length < 1}
                            variant={LTButton.Variant.PRIMARY}
                        >
                            Integrate Projects & Install
                        </LTButton>
                    </LTBox>
                </div>
            ) : (
                <form
                    action=''
                    className='flex flex-col space-y-4'
                    id='form1'
                    onSubmit={install}
                >
                    <div role="radiogroup" aria-labelledby="instanceTypeLabel"  className='flex flex-col space-y-2'>
                            <label className={`text-xs font-semibold`} aria-hidden id="instanceTypeLabel">Instance Type</label>

                        {/* <LTFormControl> */}
                            <LTRadio
                                type='radio'
                                aria-label='Cloud'
                                checked={instanceType === 'Cloud'}
                                value='Cloud'
                                readOnly
                                onClick={() => {
                                    setInstanceType('Cloud');
                                    dispatch(
                                        setInstructionState('Instructions')
                                    );
                                }}
                                label='Cloud'
                                shouldHighlightLabel={true}
                            />
                            {/* <FormControl.Label sx={{ fontSize: 12 }}>
                                Cloud
                            </FormControl.Label> */}
                        {/* </LTFormControl> */}
                        {/* <LTFormControl> */}
                            <LTRadio
                                type='radio'
                                aria-label='Self Hosted'
                                checked={instanceType === 'SelfHosted'}
                                value='SelfHosted'
                                readOnly
                                onClick={() => {
                                    setInstanceType('SelfHosted');
                                    dispatch(
                                        setInstructionState(
                                            'Instructions(SelfHosted)'
                                        )
                                    );
                                }}
                                label='Self Hosted'
                                shouldHighlightLabel={true}
                            />
                            {/* <FormControl.Label sx={{ fontSize: 12 }}>
                                Self Hosted
                            </FormControl.Label> */}
                        {/* </LTFormControl> */}
                    </div>
                    {instanceType === 'SelfHosted' &&
                        <div>
                            <LTFormControl
                                formStyles={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '8px',
                                    marginTop: '10px',
                                }}
                                label='Jira URL'
                                labelStyle={{ fontSize: 12 }}
                            >
                                {/* <FormControl.Label sx={{ fontSize: 12 }}>
                                    Jira URL
                                </FormControl.Label> */}

                                <LTTextInput
                                    type='text'
                                    id='url'
                                    aria-label='Jira URL'
                                    width={'100%'}
                                    contrast
                                    placeholder='e.g. https://jira.mydomain.com'
                                    value={host}
                                    onChange={(value :any) =>
                                        setHost(value)
                                    }
                                    inputOnly={true}
                                    required
                                />
                                {(errors as any).host && (
                                    <small className='w-full h-2.5 text-[10px] text-red-600'>
                                        {(errors as any).host && (errors as any).host[0]}
                                    </small>
                                )}
                            </LTFormControl>
                            <LTFormControl
                                formStyles={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '8px',
                                    marginTop: '10px',
                                }}
                                label='Email'
                                labelStyle={{ fontSize: 12 }}
                            >
                                {/* <FormControl.Label sx={{ fontSize: 12 }}>
                                    Email
                                </FormControl.Label> */}
                                <LTTextInput
                                    type='email'
                                    id='email'
                                    aria-label='Email'
                                    width={'100%'}
                                    contrast
                                    placeholder=''
                                    onChange={(value :any) => setUsername(value)}
                                    value={username}
                                    inputOnly={true}
                                    required
                                />
                                {(errors as any).username && (
                                    <small className='w-full h-2.5 text-[10px] text-red-600'>
                                        {(errors as any).username && (errors as any).username[0]}
                                    </small>
                                )}
                            </LTFormControl>
                            <LTFormControl
                                formStyles={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '8px',
                                    marginTop: '10px',
                                }}
                                label='Personal Access Token'
                                labelStyle={{ fontSize: 12 }}
                            >
                                {/* <FormControl.Label sx={{ fontSize: 12 }}>
                                    Personal Access Token
                                </FormControl.Label> */}
                                <LTTextInput
                                    type='password'
                                    aria-label='Token'
                                    id='token'
                                    width={'100%'}
                                    contrast
                                    placeholder=''
                                    onChange={(value :any) => setToken(value)}
                                    value={token}
                                    inputOnly={true}
                                    required
                                />
                                {(errors as any).token && (
                                    <small className='w-full h-2.5 text-[10px] text-red-600'>
                                        {(errors as any).token &&
                                            (errors as any).token[0]}
                                    </small>
                                )}
                            </LTFormControl>
                        </div>
                    }
                    <LTButton
                        variant={LTButton.Variant.PRIMARY}
                        disabled={isLoading}
                        onClick={install}
                        // type='submit'
                        // form='form1'
                        className='self-start'
                    >
                        Install
                    </LTButton>
                </form>
            )}

            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'#666666'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/jira-integration/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Personal Access Token
                </Link>
            </div>
        </LeftPanel>
    );
}
