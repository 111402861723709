import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Label, LeftPanel} from '../../Wrappers';
import {setInstructionState} from '../../../redux/features/configSlice';
import Icons from '../../../assets/images';
import {RootState} from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import {
    sanitizeAndRedirect,
    sendAmplitudeEvents,
    updatePluginList,
} from '../../../utils/helper';
import {EventNames} from '../../../utils/events';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
    Radio,
    RadioGroup,
} from '@primer/react';
import { LTButton, LTFormControl, LTRadio, LTText } from '@lambdatestincprivate/lt-components';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

export default function Github() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('OAuth');
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [isLoading, setIsLoading] = useState(false);
    const [host, setHost] = useState('');
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                host,
                token,
                instance_type: instanceType,
            });
            if (instanceType === 'OAuth') {
                sanitizeAndRedirect(res.url);
            }
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <div className='flex flex-col space-y-2' role="radiogroup" aria-labelledby="instanceTypeLabel">
                    <label
                        aria-hidden id="instanceTypeLabel"
                        className={`text-xs font-semibold`}
                    >
                        Instance
                    </label>
                    <LTRadio
                        type='radio'

                        checked={instanceType === 'OAuth'}
                        value={'OAuth'}
                        readOnly
                        onClick={() => {
                            setInstanceType('OAuth');
                            dispatch(setInstructionState('Instructions'));
                        }}
                        label='OAuth'
                        shouldHighlightLabel={true}
                    />
                    <LTRadio
                        type='radio'
                        checked={instanceType === 'Cloud'}
                        value={'Cloud'}
                        readOnly
                        onClick={() => {
                            setInstanceType('Cloud');
                            dispatch(setInstructionState('Instructions'));
                        }}
                        label='Cloud'
                        shouldHighlightLabel={true}
                    />
                    <LTRadio
                        type='radio'
                        checked={instanceType === 'SelfHosted'}
                        value={'SelfHosted'}
                        readOnly
                        onClick={() => {
                            setInstanceType('SelfHosted');
                            dispatch(
                                setInstructionState(
                                    'Instructions(SelfHosted)'
                                )
                            );
                        }}
                        label='Self Hosted'
                        shouldHighlightLabel={true}
                    />
                </div>
                {instanceType === 'SelfHosted' && (
                    <LTFormControl
                        label='Domain'
                        labelStyle={{
                            fontSize: 12,
                        }}
                        formStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <LTTextInput
                            type='text'
                            id='url'
                            aria-label='Domain'
                            width={'100%'}
                            contrast
                            placeholder='e.g., https://github.mydomain.com'
                            value={host}
                            onChange={(value :any) => setHost(value)}
                            inputOnly={true}
                            required
                        />

                        {(errors as any).host && (
                            <small className='w-full h-2.5 text-[10px] text-red-600'>
                                {(errors as any).host &&
                                    (errors as any).host[0]}
                            </small>
                        )}
                    </LTFormControl>
                )}
                {(instanceType === 'Cloud' ||
                    instanceType === 'SelfHosted') && (
                    <LTFormControl
                        label='API Token'
                        labelStyle={{
                            fontSize: 12,
                        }}
                        formStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <LTTextInput
                            type='password'
                            aria-label='API Token'
                            id='token'
                            width={'100%'}
                            contrast
                            placeholder=''
                            onChange={(value :any) => setToken(value)}
                            value={token}
                            inputOnly={true}
                            required
                        />

                        {(errors as any).token && (
                            <small className='w-full h-2.5 text-[10px] text-red-600'>
                                {(errors as any).token &&
                                    (errors as any).token[0]}
                            </small>
                        )}
                    </LTFormControl>
                )}
                <LTButton
                    variant={LTButton.Variant.PRIMARY}
                    disabled={isLoading}
                    onClick={install}
                    className='self-start'
                >
                    Install
                </LTButton>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'muted'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/github-integration/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Personal Access Token
                </Link>
            </div>
        </LeftPanel>
    );
}
