import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { setWebexOptions } from '../../../redux/features/configSlice';
import Select from 'react-select';
import { customStyles } from '../../../assets/constants/constants';
import { EventNames } from '../../../utils/events';

export default function Webex() {
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const webexOptions = useSelector(
        (state: RootState) => state.config.webexOptions
    );
    const [isLoading, setIsLoading] = useState(false);
    const [space, setSpace] = useState('');
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (space.length === 0) {
            return;
        }
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                space,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
            dispatch(setWebexOptions(null));
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <div className='flex flex-col space-y-2'>
                    <Label>Select a space</Label>
                    {webexOptions && (
                        <Select
                            isClearable={false}
                            isSearchable={true}
                            options={webexOptions?.map((elem) => {
                                return {
                                    label: elem.option,
                                    value: elem.value,
                                };
                            })}
                            onChange={(e) => setSpace(e?.value!)}
                            styles={customStyles}
                            value={webexOptions
                                .map((elem) => {
                                    return {
                                        label: elem.option,
                                        value: elem.value,
                                    };
                                })
                                .find((elem) => elem.value === space)}
                        />
                    )}
                    {(errors as any).space && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).space && (errors as any).space[0]}
                        </small>
                    )}
                </div>
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                >
                    {isLoading && (
                        <img
                            width='14px'
                            src={Icons.loaderGif}
                            alt='loader'
                            className='mr-1'
                        />
                    )}
                    Confirm Space
                </button>
            </form>
        </LeftPanel>
    );
}
