import axios from 'axios';
import { IPreferences } from '../assets/constants/constants';
import { buildCookieName, getCookie, redirectToLogin } from './helper';

const baseURL = process.env.REACT_APP_BASE_URL + '/api';
const lambdaBaseURL = process.env.REACT_APP_LAMBDA_API_URL + '/lis/api/v1';
const lambdaAuthURL = process.env.REACT_APP_AUTH_BASE_URL;
const lambdaApiURL = process.env.REACT_APP_LAMBDA_API_SDK_URL

axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
    buildCookieName()
)}`;

const Api = {
    commonApi: {
        fetchList: async () => {
            try {
                const res = await axios.get(baseURL + '/plugins-list');
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        getBuildNotificationTime: async () => {
            try{
                const res = await axios.get(
                    lambdaAuthURL + '/api/v1/user/setting'
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        setBuildNotificationTime: async (buildNotificationTime: number) => {
            try{
                const res = await axios.put(
                    lambdaAuthURL + '/api/v1/user/setting',
                    {
                        build_notification_time: buildNotificationTime
                    }
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        getPreferences: async () => {
            try {
                const res = await axios.get(
                    lambdaBaseURL + '/user/preferences'
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        updatePreferences: async (payload: IPreferences) => {
            try {
                const res = await axios.post(
                    lambdaBaseURL + '/user/preferences',
                    payload
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        removePlugin: async (code: string) => {
            try {
                if (code === 'spirateam') {
                    const res = await axios.post(
                        lambdaBaseURL + '/' + code + '/uninstall'
                    );
                    return res.data
                }
                const res = await axios.post(
                    baseURL + '/' + code + '/uninstall'
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        syncPlugin: async (code: string) => {
            try {
                if (code === 'jira' || code === 'spirateam') {
                    const res = await axios.get(
                        lambdaBaseURL + '/' + code + '/sync'
                    );
                    return res.data;
                }
                const res = await axios.get(baseURL + '/' + code + '/sync');
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        requestIntegration: async (payload: { integration: string }) => {
            try {
                const res = await axios.post(
                    baseURL + '/suggest-integration',
                    payload
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        getfeatureflag: async () => {
            try {
                const res = await axios.post(
                    lambdaApiURL + '/sdk/v1/user/flags'
                )
                return res.data;
            } catch (err: any) {
                throw err;
            }
        }
    },
    installApi: {
        redmineInstall: async (payload: {
            host: string;
            token: string;
            is_projects: boolean;
            selected_projects: number[];
        }) => {
            try {
                const res = await axios.post(
                    baseURL + '/redmine/install',
                    payload
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        fetchWebhooks: async () => {
            try {
                const res = await axios.get(lambdaBaseURL + '/user/webhooks');
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        updateWebhooks: async (payload: string) => {
            try {
                const res = await axios.post(lambdaBaseURL + '/user/webhooks', {
                    webhookURL: payload,
                });
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        deleteWebhooks: async (payload: string) => {
            try {
                const res = await axios.delete(
                    lambdaBaseURL + '/user/webhooks',
                    {
                        data: { webhookURL: payload },
                    }
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        toggleWebhooks: async (toggle: boolean) => {
            try {
                const res = await axios.post(
                    lambdaBaseURL + '/webhooks/toggleStatus',
                    {
                        status: toggle,
                    }
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        getOAuthURL: async (code: string) => {
            try {
                const res = await axios.get(
                    baseURL + '/' + code + '/oauth-url'
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        commonInstall: async (code: string, payload: any) => {
            try {
                const res = await axios.post(
                    baseURL + '/' + code + '/install',
                    payload
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        siteInstall: async (code: string, payload: any) => {
            try {
                const res = await axios.post(
                    baseURL + '/' + code + '/get-projects',
                    payload
                );
                return res.data;
            } catch (err: any) {
                throw err;
            }
        },
        apiInstall: async (code: string, payload: any) => {
            try {
                const res = await axios.post(
                    lambdaBaseURL + '/' + code + '/install',
                    payload
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
        oauthCallback: async (code: string, params: string) => {
            try {
                const res = await axios.get(
                    baseURL + '/' + code + '/auth/callback' + params
                );
                return res.data;
            } catch (err: any) {
                if (err.response.status === 401) {
                    redirectToLogin();
                }
                throw err;
            }
        },
    },
};

export default Api;
