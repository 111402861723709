import { useEffect, useState } from 'react';
import Icons from '../../../assets/images';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';

interface IWebhook {
    id: number;
    user_id: number;
    plugin_id: number;
    key: string;
    value: string;
    createdAt: string;
    updatedAt: string;
}

export default function Webhooks() {
    const [webhookURL, setWebhookURL] = useState('');
    const [webhookURLList, setWebhookURLList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await Api.installApi.fetchWebhooks();
                setWebhookURLList(res);
            } catch (err: any) {
                Toaster.error(err.response.data.message);
            }
        }
        fetchData();
    }, []);

    const handleSubmit = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await Api.installApi.toggleWebhooks(true);
            await Api.installApi.updateWebhooks(webhookURL);
            const res = await Api.installApi.fetchWebhooks();
            setWebhookURLList(res);
            setIsLoading(false);
            setWebhookURL('');
        } catch (err: any) {
            Toaster.error(err.response.data.message);
            setIsLoading(false);
        }
    };

    const handleDelete = async (url: string) => {
        try {
            await Api.installApi.deleteWebhooks(url);
            const res = await Api.installApi.fetchWebhooks();
            setWebhookURLList(res);
        } catch (err: any) {
            Toaster.error(err.response.data.message);
        }
    };

    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={handleSubmit}
            >
                <div className='flex flex-col space-y-2'>
                    <Label>Webhook URL</Label>
                    <div className='flex border-1  border-gray-300 rounded-sm'>
                        <input
                            type='text'
                            id='url'
                            aria-label='Url'
                            onChange={(e) => setWebhookURL(e.target.value)}
                            value={webhookURL}
                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                            placeholder='Add Webhooks'
                            required
                        />
                    </div>
                </div>
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                >
                    {isLoading && (
                        <img
                            width='14px'
                            src={Icons.loaderGif}
                            alt='loader'
                            className='mr-1'
                        />
                    )}
                    Add
                </button>
                {webhookURLList.length > 0 && (
                    <div className='my-4'>
                        <div className='w-full h-6 font-bold border-b-2 border-zinc-200'>
                            Webhook URL
                        </div>
                        <div>
                            {webhookURLList.map(
                                (element: IWebhook, index: number) => {
                                    return (
                                        <div
                                            className={`flex justify-between items-center h-9 ${
                                                index % 2 !== 0 && 'bg-zinc-50'
                                            }`}
                                        >
                                            <div>{element.value}</div>
                                            <button
                                                type='button'
                                                className='w-[70px] h-6 bg-neutral-900 px-3 rounded hover:bg-neutral-800 text-white'
                                                onClick={() =>
                                                    handleDelete(element.value)
                                                }
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                )}
            </form>
        </LeftPanel>
    );
}
