import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
    setInstructionState,
    setSideBarObject,
} from '../redux/features/configSlice';
import Icon from '../assets/images';
import InstructionsPanel from './InstructionsPanel';
import DynamicLoader from './DynamicLoader';
import { useState } from 'react';
import { Label, LeftPanel } from './Wrappers';
import Icons from '../assets/images';
import Api from '../utils/api';
import Toaster from './Toaster/Index';
import GoogleButton from 'react-google-button';
import { EventNames } from '../utils/events';
import { sendAmplitudeEvents } from '../utils/helper';
import { oauthDocumentationLink } from '../assets/constants/constants';

export default function Sidebar() {
    const [isLoading, setIsLoading] = useState(false);
    const sidebarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const googleChatOptions = useSelector(
        (state: RootState) => state.config.googleChatOptions
    );
    const webexOptions = useSelector(
        (state: RootState) => state.config.webexOptions
    );
    const zohoCliqOptions = useSelector(
        (state: RootState) => state.config.zohoCliqOptions
    );

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sidebarObject.category,
            plugin_name: sidebarObject.name,
        });
        try {
            const res = await Api.installApi.getOAuthURL(sidebarObject.code);
            if (res.url) {
                window.open(res.url, '_self');
            }
            setIsLoading(false);
        } catch (err: any) {
            Toaster.error(err.response.data.message);
            setIsLoading(false);
        }
    };
    const dispatch = useDispatch();
    return (
        <fieldset
            className='h-full oldCanvas'
            aria-label={sidebarObject.name + ' sidebar'}
        >
            <div className='w-full h-28 bg-zinc-50 border-b border-gray-300 p-6 text-zinc-800 tracking-[0.5px]'>
                <button
                    onClick={() => {
                        dispatch(setSideBarObject({}));
                        dispatch(setInstructionState('Instructions'));
                    }}
                    className='w-2.5 h-2.5 absolute top-7 right-7'
                    aria-label='Close Sidebar'
                >
                    <img
                        src={Icon.closeIcon}
                        alt='Close Icon'
                        role='presentation'
                    />
                </button>
                <div className='flex'>
                    <img
                        src={sidebarObject.logo}
                        alt={sidebarObject.name + ' logo'}
                        className='w-[62px] mr-6'
                    />
                    <div>
                        <h2 className='text-base font-semibold'>
                            {sidebarObject.name}
                        </h2>
                        <div className='flex text-xs mt-2'>
                            <div className='grid w-32'>
                                <div className='text-neutral-600 mb-px'>
                                    Requirement
                                </div>
                                <div>Admin/User Role</div>
                            </div>
                            <div className='grid w-32'>
                                <div className='text-neutral-600 mb-px'>
                                    Setup time
                                </div>
                                <div>About 3 minutes</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='flex w-full h-full'
                style={{ height: 'calc(100% - 112px)' }}
            >
                {sidebarObject.is_oauth === 1 &&
                !webexOptions &&
                !googleChatOptions &&
                !zohoCliqOptions ? (
                    <LeftPanel>
                        <form
                            action=''
                            className='flex flex-col space-y-4'
                            id='form1'
                            onSubmit={install}
                        >
                            {sidebarObject.code === 'google-chat' ? (
                                <GoogleButton
                                    onClick={install}
                                    type='light'
                                    style={{
                                        transform: 'scale(0.8)',
                                        transformOrigin: 'left',
                                    }}
                                />
                            ) : (
                                <button
                                    type='submit'
                                    form='form1'
                                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                                >
                                    {isLoading && (
                                        <img
                                            width='14px'
                                            src={Icons.loaderGif}
                                            alt='loader'
                                            className='mr-1'
                                        />
                                    )}
                                    Install
                                </button>
                            )}
                        </form>
                        {sidebarObject.code in oauthDocumentationLink && (
                            <div
                                className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'
                                aria-label='Help Group'
                            >
                                <Label>Know how to generate</Label>
                                <a
                                    href={
                                        oauthDocumentationLink[
                                            sidebarObject.code as keyof typeof oauthDocumentationLink
                                        ]
                                    }
                                    target='_blank'
                                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                                    rel='noreferrer'
                                >
                                    Personal Access Token
                                </a>
                            </div>
                        )}
                    </LeftPanel>
                ) : (
                    <DynamicLoader
                        component={sidebarObject.name.replace(/ /g, '')}
                    />
                )}

                <InstructionsPanel />
            </div>
        </fieldset>
    );
}
