import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { RootState } from '../redux/store';
import Icons from '../assets/images';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LTBox } from '@lambdatestincprivate/lt-components';

export default function InstructionsPanel() {
    const [instructions, setInstructions] = useState('');
    const [description, setDescription] = useState('');
    const [security, setSecurity] = useState('');
    const [isExpanded, setIsExpanded] = useState({
        1: true,
        2: false,
        3: false,
    });
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const instructionsState = useSelector(
        (state: RootState) => state.config.instructionState
    );
    useEffect(() => {
        if (Object.keys(sideBarObject).length > 0) {
            const folder = sideBarObject.name.replace(/ /g, '');
            const descriptionFile =
                require(`!!raw-loader!./Integrations/${folder}/Description.md`).default;
            setDescription(descriptionFile);
            const securityFile =
                require(`!!raw-loader!./Integrations/${folder}/Security.md`).default;
            setSecurity(securityFile);
        }
    }, [sideBarObject]);

    useEffect(() => {
        if (Object.keys(sideBarObject).length > 0) {
            const folder = sideBarObject.name.replace(/ /g, '');
            const instructionsFile =
                require(`!!raw-loader!./Integrations/${folder}/${instructionsState}.md`).default;
            setInstructions(instructionsFile);
        }
    }, [sideBarObject, instructionsState]);

    return (
        <LTBox
            className='right-panel w-[50%] p-[16px] pr-[24px] tracking-[0.5px]'
            bg={'bg.muted'}
        >
            <div className='flex flex-col space-y-6'>
                <div>
                    <button
                        onClick={() =>
                            setIsExpanded({ ...isExpanded, 1: !isExpanded[1] })
                        }
                        className='w-full focus-black'
                    >
                        <div className='flex justify-between mb-3 cursor-pointer'>
                            <span className='text-sm font-semibold text-zinc-800'>
                                Instructions
                            </span>
                            <img
                                src={
                                    Icons[
                                        isExpanded[1] ? 'upArrow' : 'downArrow'
                                    ]
                                }
                                alt='Icon'
                                aria-hidden
                            />
                        </div>
                    </button>
                    {isExpanded[1] && (
                        <LTBox
                            className='htmlContent'
                            sx={{
                                borderBottom: 1,
                                borderStyle: 'solid',
                                borderColor: 'border.default',
                            }}
                        >
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                children={instructions}
                            />
                        </LTBox>
                    )}
                </div>
                <div>
                    <button
                        onClick={() =>
                            setIsExpanded({ ...isExpanded, 2: !isExpanded[2] })
                        }
                        className='w-full focus-black'
                    >
                        <div className='flex justify-between mb-3 cursor-pointer'>
                            <span className='text-sm font-semibold text-zinc-800'>
                                Description
                            </span>
                            <img
                                src={
                                    Icons[
                                        isExpanded[2] ? 'upArrow' : 'downArrow'
                                    ]
                                }
                                alt='Icon'
                                aria-hidden
                            />
                        </div>
                    </button>
                    {isExpanded[2] && (
                        <LTBox
                            className='htmlContent'
                            sx={{
                                borderBottom: 1,
                                borderStyle: 'solid',
                                borderColor: 'border.default',
                            }}
                        >
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                children={description}
                            />
                        </LTBox>
                    )}
                </div>
                <div>
                    <button
                        onClick={() =>
                            setIsExpanded({ ...isExpanded, 3: !isExpanded[3] })
                        }
                        className='w-full focus-black'
                    >
                        <div className='flex justify-between mb-3 cursor-pointer'>
                            <span className='text-sm font-semibold text-zinc-800'>
                                Security
                            </span>
                            <img
                                src={
                                    Icons[
                                        isExpanded[3] ? 'upArrow' : 'downArrow'
                                    ]
                                }
                                alt='Icon'
                                aria-hidden
                            />
                        </div>
                    </button>
                    {isExpanded[3] && (
                        <LTBox
                            className='htmlContent'
                            sx={{
                                borderBottom: 1,
                                borderStyle: 'solid',
                                borderColor: 'border.default',
                            }}
                        >
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                children={security}
                            />
                        </LTBox>
                    )}
                </div>
            </div>
        </LTBox>
    );
}
