import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LeftPanel } from '../../Wrappers';
import { setInstructionState } from '../../../redux/features/configSlice';
import { RootState } from '../../../redux/store';
import {
    sanitizeAndRedirect,
    sendAmplitudeEvents,
    updatePluginList,
} from '../../../utils/helper';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import Icons from '../../../assets/images';
import { EventNames } from '../../../utils/events';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
    Radio,
    RadioGroup,
} from '@primer/react';
// import { Select } from '@primer/react';
import { customStyles } from '../../../assets/constants/constants';
import { LTButton, LTFormControl, LTRadio, LTSelect, LTText } from '@lambdatestincprivate/lt-components';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

export default function AzureDevOps() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('cloud');
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const azureDevOpsOptions = useSelector(
        (state: RootState) => state.config.azureDevOpsOptions
    );
    const [isLoading, setIsLoading] = useState(false);
    const [host, setHost] = useState('');
    const [token, setToken] = useState('');
    const [org, setOrg] = useState('');
    const [orgName, setOrgName] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        const data: any = {};
        if (azureDevOpsOptions && azureDevOpsOptions.length > 0) {
            data['org'] = org;
        } else {
            data['instance_type'] =
                instanceType === 'cloud' ? 'Cloud' : 'SelfHosted';
            data['host'] = host;
            data['token'] = token;
        }

        try {
            const res = await Api.installApi.commonInstall(
                sideBarObject.code,
                data
            );

            if (instanceType === 'cloud') {
                sanitizeAndRedirect(res.url);
            }

            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    useEffect(() => {
        if (azureDevOpsOptions && azureDevOpsOptions.length > 0) {
            setOrg(azureDevOpsOptions[0].value);
            setOrgName(azureDevOpsOptions[0].option);
        }
    }, []);

    const isOrgSelected = () => {
        return !(
            azureDevOpsOptions &&
            azureDevOpsOptions.length > 0 &&
            org.length === 0
        );
    };

    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                {azureDevOpsOptions && azureDevOpsOptions.length > 0 ? (
                    <LTFormControl
                        label='Select your Organization'
                        labelStyle={{
                            fontSize: 12,
                        }}
                        formStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <LTSelect
                            options={azureDevOpsOptions.map((elem) => ({
                                name: elem.option,
                                value: elem.value,
                            }))}
                            onChange={(values :any) => {
                                setOrg(values[0].value);
                                setOrgName(values[0].name);
                            }}
                            customButtonText={orgName ?? 'Select Org'}
                            buttonStyle={{
                                width: '100%',
                                maxWidth: '100%',
                                '[data-component="buttonContent"]': {
                                justifyContent: 'start',
                                },
                            }}
                        />
                    </LTFormControl>
                ) : (
                    <>
                        <div
                            className='flex flex-col space-y-2'
                            role='radiogroup'
                            aria-labelledby='instanceTypeLabel'
                        >
                            <label
                                aria-hidden
                                id='instanceTypeLabel'
                                className={`text-xs font-semibold`}
                            >
                                Instance
                            </label>

                            <LTRadio
                                type='radio'
                                checked={instanceType === 'cloud'}
                                value={'Cloud'}
                                readOnly
                                onClick={() => {
                                    setInstanceType('cloud');
                                    dispatch(
                                        setInstructionState('Instructions')
                                    );
                                }}
                                label='Cloud'
                                shouldHighlightLabel={true}
                            />
                            <LTRadio
                                type='radio'
                                checked={instanceType === 'self_hosted'}
                                value={'SelfHosted'}
                                readOnly
                                onClick={() => {
                                    setInstanceType('self_hosted');
                                    dispatch(
                                        setInstructionState(
                                            'Instructions(SelfHosted)'
                                        )
                                    );
                                }}
                                label='Self Hosted'
                                shouldHighlightLabel={true}
                            />
                        </div>
                        {instanceType === 'self_hosted' && (
                            <>
                                <LTFormControl
                                    label='Azure Devops URL'
                                    labelStyle={{
                                        fontSize: 12,
                                    }}
                                    formStyles={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                    }}
                                >
                                    <LTTextInput
                                        type='text'
                                        id='url'
                                        aria-label='Azure Devops URL'
                                        width={'100%'}
                                        contrast
                                        placeholder={
                                            'https://dev.azure.com/lambdatest'
                                        }
                                        onChange={(value :any) =>
                                            setHost(value)
                                        }
                                        value={host}
                                        inputOnly={true}
                                        required
                                    />

                                    {(errors as any).host && (
                                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                                            {(errors as any).host &&
                                                (errors as any).host[0]}
                                        </small>
                                    )}
                                </LTFormControl>
                                <LTFormControl
                                    label='Access Token'
                                    labelStyle={{
                                        fontSize: 12,
                                    }}
                                    formStyles={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                    }}
                                >
                                    <LTTextInput
                                        type='password'
                                        aria-label='Access Token'
                                        id='token'
                                        width={'100%'}
                                        contrast
                                        placeholder='Please Enter Your Access Token'
                                        onChange={(value :any) =>
                                            setToken(value)
                                        }
                                        value={token}
                                        inputOnly={true}
                                        required
                                    />

                                    {(errors as any).token && (
                                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                                            {(errors as any).token &&
                                                (errors as any).token[0]}
                                        </small>
                                    )}
                                </LTFormControl>
                            </>
                        )}
                    </>
                )}
                <LTButton
                    variant={LTButton.Variant.PRIMARY}
                    disabled={!isOrgSelected() || isLoading}
                    onClick={install}
                    className='self-start'
                >
                    Install
                </LTButton>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'muted'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/vsts-integration/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Personal Access Token
                </Link>
            </div>
        </LeftPanel>
    );
}
