import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LeftPanel } from '../../Wrappers';
import { setInstructionState } from '../../../redux/features/configSlice';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { EventNames } from '../../../utils/events';
import { customStyles } from '../../../assets/constants/constants';
import Select from 'react-select';

interface IProject {
    id: string;
    key: string;
    name: string;
}

export default function Jira() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('Cloud');
    const jiraOptions = useSelector(
        (state: RootState) => state.config.jiraOptions
    );
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [site, setSite] = useState('');
    const [siteUrl, setSiteUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [host, setHost] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [username, setUsername] = useState('');
    const [token, setToken] = useState('');
    const [errors, setErrors] = useState({});
    const [projectsList, setProjectsList] = useState<IProject[]>([]);
    const [tempProjectList, setTempProjectList] = useState<IProject[]>([]);
    const [selectedProjects, setSelectedProjects] = useState<IProject[]>([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [projectLoading, setProjectLoading] = useState(false);
    const [lastSearched, setLastSearched] = useState('');
    const [searchedCount, setSearchedCount] = useState(0);

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            if (instanceType === 'Cloud') {
                const res = await Api.installApi.getOAuthURL(sideBarObject.code);
                if (res.url) {
                    window.open(res.url, '_self');
                    setIsLoading(false);
                    return;
                }
            }
            else if (instanceType === 'SelfHosted' && /\s/.test(host)) {
                setErrors({ host: ['Please enter a valid URL'] });
                setIsLoading(false);
                return;
            }
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                host,
                subdomain,
                instance_type: instanceType,
                username: username,
                token: token,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            if (res.projects && res.projects.length > 0) {
                setProjectsList(res.projects);
                if (instanceType === 'Cloud') {
                    setTotalProjects(res.total);
                    setCurrentPage(1);
                    setSearchedCount(res.total);
                } else {
                    setTempProjectList(res.projects);
                    setTotalProjects(res.projects.length);
                }
            }
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const siteInstall = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if(site) {
            try {
                setIsLoading(true);
                const res = await Api.installApi.siteInstall(sideBarObject.code, {
                    site: site,
                    siteUrl: siteUrl
                });
                if (res.message) {
                    Toaster.success(res.message);
                }
                if (res.projects && res.projects.length > 0) {
                    setProjectsList(res.projects);
                    setTotalProjects(res.total);
                    setCurrentPage(1);
                    setSearchedCount(res.total);
                }
                setIsLoading(false);
            } catch (err: any) {
                if (err.response.data.message) {
                    Toaster.error(err.response.data.message);
                }
                setIsLoading(false);
            }
        }
    }

    const projectInstall = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (selectedProjects.length < 1) {
            return;
        }
        try {
            setIsLoading(true);
            const res = await Api.installApi.apiInstall(sideBarObject.code, {
                selected_projects: selectedProjects.map((x) => x.key),
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            await updatePluginList();
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
            setIsLoading(false);
        }
    };

    const increement = async () => {
        if (currentPage + 1 > Math.ceil(searchedCount / 50)) {
            return;
        }
        const newCurrentPage = currentPage + 1;
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                page: newCurrentPage,
                ...(lastSearched.length > 0 && { search: lastSearched }),
            });
            setCurrentPage(newCurrentPage);
            setProjectLoading(false);
            setProjectsList(res.projects);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const decreement = async () => {
        if (currentPage - 1 <= 0) {
            return;
        }
        const newCurrentPage = currentPage - 1;
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                page: newCurrentPage,
                ...(lastSearched.length > 0 && { search: lastSearched }),
            });
            setCurrentPage(newCurrentPage);
            setProjectLoading(false);
            setProjectsList(res.projects);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const checkProject = (checked: boolean, element: IProject) => {
        if (checked === false) {
            setSelectedProjects(
                selectedProjects.filter((elem) => elem.id !== element.id)
            );
        } else {
            setSelectedProjects([...selectedProjects, element]);
        }
    };

    const searchProject = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (search.length === 0) {
            return 0;
        }
        if (instanceType === 'SelfHosted') {
            setProjectsList(
                tempProjectList.filter(
                    (e) =>
                        e.name.toLowerCase().indexOf(search.toLowerCase()) > 0
                )
            );
            setLastSearched(search);
            return;
        }
        setProjectLoading(true);
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                search,
            });
            if (res.projects && res.total > 0) {
                setProjectsList(res.projects);
                setSearchedCount(res.total);
                setCurrentPage(1);
                setLastSearched(search);
            }
            if (res.type === 'error' && res.message) {
                Toaster.error(res.message);
            }
            setProjectLoading(false);
        } catch (err: any) {
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
            setProjectLoading(false);
        }
    };

    const searchClear = async () => {
        setSearch('');
        setLastSearched('');
        if (instanceType === 'SelfHosted') {
            setProjectsList([...tempProjectList]);
            return;
        }
        setProjectLoading(true);
        const res = await Api.installApi.commonInstall(sideBarObject.code, {
            page: 1,
        });
        if (res.projects && res.projects.length > 0) {
            setProjectsList(res.projects);
            setSearchedCount(res.total);
            setTotalProjects(res.total);
            setCurrentPage(1);
        }
        setProjectLoading(false);
    };

    useEffect(() => {
        if (sideBarObject.is_installed) {
            searchClear();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <LeftPanel>
            {jiraOptions && jiraOptions.length > 0 && totalProjects === 0 ? (
                <div className='flex flex-col space-y-2'>
                    <Label>Select your Site</Label>
                    <Select
                        isClearable={false}
                        isSearchable={true}
                        options={jiraOptions?.map((elem) => {
                            return {
                                label: elem.name,
                                value: elem.id,
                                url: elem.url
                            };
                        })}
                        onChange={(e) => {
                            setSite(e?.value!)
                            setSiteUrl(e?.url!);
                        }} 
                        styles={customStyles}
                        value={jiraOptions
                            .map((elem) => {
                                return {
                                    label: elem.name,
                                    value: elem.id,
                                    url: elem.url
                                };
                            })
                            .find((elem) => elem.value === site)}
                    />
                    <button
                        className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                        onClick={siteInstall}
                    >
                        {isLoading && (
                            <img
                                width='14px'
                                src={Icons.loaderGif}
                                alt='loader'
                                className='mr-1'
                            />
                        )}
                        Select Site & Proceed
                    </button>
                </div>
            ) : totalProjects > 0 || sideBarObject.is_installed ? (
                <div
                    className={`flex flex-col space-y-2 h-full ${
                        projectLoading ? 'blur-sm pointer-events-none' : ''
                    }`}
                >
                    <div className='border-y-[1px] border-gray-300 text-sm py-2.5 flex justify-between'>
                        <div>Select Projects</div>
                        <div className='flex'>
                            <div>{`${selectedProjects.length}  /${totalProjects}`}</div>
                            <div className='ml-3 opacity-60'>Selected</div>
                        </div>
                    </div>
                    {selectedProjects.length > 0 && (
                        <div>
                            {selectedProjects.map((project) => (
                                <div className='text-gray-800 text-xs font-medium mr-1 mb-1 px-1.5 py-0.5 rounded border-1  border-gray-500 inline-flex'>
                                    {project.name}
                                    <img
                                        src={Icons.closeIcon}
                                        alt='close'
                                        className='w-2 ml-0.5 cursor-pointer'
                                        onClick={() =>
                                            checkProject(false, project)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className='text-xs overflow-y-scroll'>
                        <div className='flex p-2.5 bg-zinc-50 justify-between'>
                            <form
                                className='flex border-1  border-gray-300 rounded-sm w-full'
                                id='form2'
                                onSubmit={searchProject}
                            >
                                <input
                                    type='text'
                                    aria-label='InputText'
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    className='placeholder-stone-500 focus:outline-none focus:border-px h-8 px-2 w-full'
                                    placeholder='Press Enter to Search'
                                />
                            </form>
                            {lastSearched.length > 0 && (
                                <button
                                    className='py-2.5 bg-neutral-900 px-2.5 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center ml-3'
                                    onClick={searchClear}
                                >
                                    Clear
                                </button>
                            )}
                        </div>
                        {projectsList.map((element, index) => {
                            return (
                                <label
                                    className={`flex py-2 px-2.5 hover:cursor-pointer ${
                                        index % 2 !== 0 && 'bg-zinc-50'
                                    }`}
                                >
                                    <input
                                        type={'checkbox'}
                                        className='mr-2.5'
                                        onChange={(e) =>
                                            checkProject(
                                                e.target.checked,
                                                element
                                            )
                                        }
                                        checked={selectedProjects.some(
                                            (p) => p.id === element.id
                                        )}
                                    />
                                    {element.name}
                                </label>
                            );
                        })}
                    </div>
                    {instanceType === 'Cloud' && searchedCount > 0 && (
                        <div className='text-neutral-600 flex'>
                            Showing{' '}
                            {Math.min(
                                searchedCount,
                                (currentPage - 1) * 50 + 1
                            )}{' '}
                            - {Math.min(searchedCount, currentPage * 50)} of{' '}
                            {searchedCount}
                            <div
                                className='cursor-pointer ml-3 mr-1'
                                onClick={decreement}
                            >
                                {'<'}
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={increement}
                            >
                                {'>'}
                            </div>
                        </div>
                    )}
                    <button
                        className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                        onClick={projectInstall}
                        disabled={isLoading || selectedProjects.length < 1}
                    >
                        {isLoading && (
                            <img
                                width='14px'
                                src={Icons.loaderGif}
                                alt='loader'
                                className='mr-1'
                            />
                        )}
                        Integrate Projects & Install
                    </button>
                </div>
            ) : (
                <form
                    action=''
                    className='flex flex-col space-y-4'
                    id='form1'
                    onSubmit={install}
                >
                    <div className='flex flex-col space-y-2' role="radiogroup" aria-labelledby="instanceTypeLabel">
                        <label id="instanceTypeLabel">Instance Type</label>
                        <div className='flex flex-row space-x-5'>
                            <div className='flex flex-row space-x-1 items-center'>
                                <input
                                    id="cloudInstance"
                                    type='radio'
                                    name="instanceType"
                                    aria-checked={instanceType === 'Cloud'}
                                    checked={instanceType === 'Cloud'}
                                    readOnly
                                    onClick={() => {
                                        setInstanceType('Cloud');
                                        dispatch(setInstructionState('Instructions'));
                                    }}
                                />
                                <label htmlFor="cloudInstance">Cloud</label>
                            </div>
                            <div className='flex flex-row space-x-1 items-center'>
                                <input
                                    id="selfHostedInstance"
                                    type='radio'
                                    name="instanceType"
                                    aria-checked={instanceType === 'SelfHosted'}
                                    checked={instanceType === 'SelfHosted'}
                                    readOnly
                                    onClick={() => {
                                        setInstanceType('SelfHosted');
                                        dispatch(setInstructionState('Instructions(SelfHosted)'));
                                    }}
                                />
                                <label htmlFor="selfHostedInstance">Self Hosted</label>
                            </div>
                        </div>
                    </div>
                    {instanceType === 'SelfHosted' &&
                    <div>
                        <div className='flex flex-col space-y-2'>
                            <Label>Jira URL</Label>
                            <div className='flex border-1  border-gray-300 rounded-sm'>

                                <input
                                    type='text'
                                    id='url'
                                    aria-label='Url'
                                    className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                    placeholder='e.g., https://jira.mydomain.com'
                                    value={host}
                                    onChange={(e) =>
                                        setHost(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            {(errors as any).host && (
                                <small className='w-full h-2.5 text-[10px] text-red-600'>
                                    {(errors as any).host &&
                                        (errors as any).host[0]}
                                </small>
                            )}
                        </div>
                        <div className='flex flex-col space-y-2'>
                            <Label>Email</Label>
                            <div className='flex border-1  border-gray-300 rounded-sm'>
                                <input
                                    type='email'
                                    id='email'
                                    aria-label='Email'
                                    className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                    placeholder=''
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username}
                                    required
                                />
                            </div>
                            {(errors as any).username && (
                                <small className='w-full h-2.5 text-[10px] text-red-600'>
                                    {(errors as any).username &&
                                        (errors as any).username[0]}
                                </small>
                            )}
                        </div>
                        <div className='flex flex-col space-y-2'>
                            <Label>
                                Personal Access Token
                            </Label>
                            <div className='flex border-1  border-gray-300 rounded-sm'>
                                <input
                                    type='password'
                                    aria-label='Password'
                                    id='token'
                                    className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                    placeholder=''
                                    onChange={(e) => setToken(e.target.value)}
                                    value={token}
                                    required
                                />
                            </div>
                            {(errors as any).token && (
                                <small className='w-full h-2.5 text-[10px] text-red-600'>
                                    {(errors as any).token &&
                                        (errors as any).token[0]}
                                </small>
                            )}
                        </div>
                    </div>
                    }
                    <button
                        type='submit'
                        form='form1'
                        className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                    >
                        {isLoading && (
                            <img
                                width='14px'
                                src={Icons.loaderGif}
                                alt='loader'
                                className='mr-1'
                            />
                        )}
                        Install
                    </button>
                </form>
            )}

            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'>
                <Label>Know how to generate</Label>
                <a
                    href='https://www.lambdatest.com/support/docs/jira-integration/'
                    target='_blank'
                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                    rel='noreferrer'
                >
                    Personal Access Token
                </a>
            </div>
        </LeftPanel>
    );
}
