import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './Home';
import ErrorPage from './ErrorPage';
import { oauthCallbackRoutes, routeCodes } from '../assets/constants/constants';
import LTCommonHeader from '@lambdatestincprivate/lt-common-header';
import { useEffect } from 'react';
import './index.css';

const redirectUrl = `${
    process.env.REACT_APP_ACCOUNTS_URL || 'https://accounts.lambdatest.com'
}/logout`;

const Redirect = () => {
    window.location.href = redirectUrl;
    return <></>;
};

function OldApp() {
    return (
        <Router>
            <ToastContainer
                position='top-right'
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                className={'toasterOld'}
            />
            <Routes>
                <Route
                    path='/'
                    element={<HandleCommonHeader />}
                />
                {routeCodes.map((route) => (
                    <Route
                        path={`/${route}/install`}
                        element={<HandleCommonHeader />}
                    />
                ))}
                {oauthCallbackRoutes.map((route) => (
                    <Route
                        path={`/${route}/auth/callback`}
                        element={<HandleCommonHeader />}
                    />
                ))}
                <Route
                    path='/logout'
                    element={<Redirect />}
                />
                <Route
                    path='*'
                    element={<ErrorPage />}
                />
            </Routes>
        </Router>
    );
}

function HandleCommonHeader() {
    useEffect(() => {
        const content = document.getElementById('left_sidebar_header');
        if (content) content!.style!.justifyContent = 'space-between';
    }, []);

    return (
        <LTCommonHeader
            showTunnel
            customProductHeader={['Integrations']}
        >
            <Home />
        </LTCommonHeader>
    );
}

export default OldApp;
