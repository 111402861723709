import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LeftPanel } from '../../Wrappers';
import { setInstructionState } from '../../../redux/features/configSlice';
import { RootState } from '../../../redux/store';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import Icons from '../../../assets/images';
import { EventNames } from '../../../utils/events';

export default function AzureDevOps() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('cloud');
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [isLoading, setIsLoading] = useState(false);
    const [user_key, setUserKey] = useState('');
    const [project_id, setProjectId] = useState('');
    const [project_key, setProjectKey] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                user_key,
                project_id,
                project_key,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <div className='flex flex-col space-y-2'>
                    <Label>Airbrake Auth Token</Label>
                    <div className='flex border-1  border-gray-300 rounded-sm'>
                        <input
                            type='password'
                            aria-label='Password'
                            id='project_id'
                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                            placeholder='Enter Your Airbrake Auth Token'
                            onChange={(e) => setUserKey(e.target.value)}
                            value={user_key}
                            required
                        />
                    </div>
                    {(errors as any).user_key && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).user_key &&
                                (errors as any).user_key[0]}
                        </small>
                    )}
                </div>

                <div className='flex flex-col space-y-2'>
                    <Label>Airbrake Project ID</Label>
                    <div className='flex border-1  border-gray-300 rounded-sm'>
                        <input
                            type='text'
                            aria-label='InputText'
                            id='project_id'
                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                            placeholder='Enter Your Airbrake Project ID'
                            onChange={(e) => setProjectId(e.target.value)}
                            value={project_id}
                            required
                        />
                    </div>
                    {(errors as any).project_id && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).project_id &&
                                (errors as any).project_id[0]}
                        </small>
                    )}
                </div>
                <div className='flex flex-col space-y-2'>
                    <Label>Airbrake Project Key</Label>
                    <div className='flex border-1  border-gray-300 rounded-sm'>
                        <input
                            type='password'
                            aria-label='Password'
                            id='project_key'
                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                            placeholder='Enter Your Aibrake Project Key'
                            onChange={(e) => setProjectKey(e.target.value)}
                            value={project_key}
                            required
                        />
                    </div>
                    {(errors as any).project_key && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).project_key &&
                                (errors as any).project_key[0]}
                        </small>
                    )}
                </div>
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                >
                    {isLoading && (
                        <img
                            width='14px'
                            src={Icons.loaderGif}
                            alt='loader'
                            className='mr-1'
                        />
                    )}
                    Install
                </button>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'>
                <Label>Know how to generate</Label>
                <a
                    href='https://www.lambdatest.com/support/docs/airbrake-integration'
                    target='_blank'
                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                    rel='noreferrer'
                >
                    Airbrake Project ID &amp; Key
                </a>
            </div>
        </LeftPanel>
    );
}
