import {
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ActionList, ActionMenu, Box, Text } from '@primer/react';
import { ToggleSwitch } from './Wrappers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Toaster from './Toaster/Index';
import { setPreferences } from '../redux/features/configSlice';
import Api from '../utils/api';
import { webhookCode } from '../assets/constants/constants';
import { LTBox, LTText } from '@lambdatestincprivate/lt-components';

const notificationOptions = [
    { label: '1 Minute', value: 60 },
    { label: '2 Minutes', value: 120 },
    { label: '3 Minutes', value: 180 },
    { label: '4 Minutes', value: 240 },
    { label: '5 Minutes', value: 300 },
    { label: '6 Minutes', value: 360 },
    { label: '7 Minutes', value: 420 },
    { label: '8 Minutes', value: 480 },
    { label: '9 Minutes', value: 540 },
    { label: '10 Minutes', value: 600 },
    { label: '11 Minutes', value: 660 },
    { label: '12 Minutes', value: 720 },
    { label: '13 Minutes', value: 780 },
    { label: '14 Minutes', value: 840 },
    { label: '15 Minutes', value: 900 },
];

interface IUserPreferences {
    screenshotTestCompletion: boolean;
    buildCompletion: boolean;
    testFail: boolean;
    testPass: boolean;
    buildNotificationTime: number;
}

export function Settings({ plugin }: { plugin: string }) {
    const [open, setOpen] = useState(false);
    const preferences = useSelector(
        (state: RootState) => state.config.preferences
    );
    const dispatch = useDispatch();

    const [userPreferences, setUserPreferences] =
        useStateCallback<IUserPreferences>({
            screenshotTestCompletion: false,
            buildCompletion: false,
            testFail: false,
            testPass: false,
            buildNotificationTime: 900,
        });

    useEffect(() => {
        setUserPreferences({
            screenshotTestCompletion: preferences.screenshotTestCompletion,
            buildCompletion: preferences.buildCompletion,
            testFail: preferences.testFail,
            testPass: preferences.testPass,
            buildNotificationTime: preferences.buildNotificationTime,
        });
    }, [preferences, setUserPreferences]);

    const handleChange = (target: string, value: boolean | number) => {
        setUserPreferences(
            { ...userPreferences, [target]: value },
            (userPref) => {
                handlePreferences(userPref, target);
            }
        );
    };

    const handlePreferences = async (
        userPref: SetStateAction<IUserPreferences> | undefined,
        target: string
    ) => {
        const newPreferences = {
            ...preferences,
            ...userPref,
        };
        try {
            let res;
            if (target === 'buildNotificationTime') {
                res = await Api.commonApi.setBuildNotificationTime(
                    newPreferences.buildNotificationTime
                );
            } else {
                res = await Api.commonApi.updatePreferences(newPreferences);
            }
            if (res.message) {
                Toaster.success(res.message);
            }
            dispatch(setPreferences(newPreferences));
        } catch (err: any) {
            if (err.response && err.response.data)
                Toaster.error(err.response.data.message);
        }
    };

    return (
        <LTBox
            height={'100%'}
            p={16}
        >
            <LTText
                as='h6'
                sx={{
                    borderBottom: 1,
                    borderStyle: 'solid',
                    borderColor: 'border.default',
                    paddingBottom: '16px',
                }}
            >
                Notification Preferences
            </LTText>
            <LTBox
                paddingY={'16px'}
                display={'flex'}
                flexDirection={'column'}
                sx={{ gap: '16px' }}
            >
                {plugin !== webhookCode && (
                    <LTBox
                        width={'100%'}
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <LTText fontSize={14}>
                            Screenshot Test Completion Messages
                        </LTText>
                        <ToggleSwitch
                            enabled={userPreferences.screenshotTestCompletion}
                            target={'screenshotTestCompletion'}
                            handleChange={handleChange}
                        />
                    </LTBox>
                )}

                <LTBox
                    width={'100%'}
                    justifyContent={'space-between'}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <LTText fontSize={14}>Build completion messages</LTText>
                    <ToggleSwitch
                        enabled={userPreferences.buildCompletion}
                        target={'buildCompletion'}
                        handleChange={handleChange}
                    />
                </LTBox>
                <LTBox
                    width={'100%'}
                    justifyContent={'space-between'}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <LTText fontSize={14}>Test Fail messages</LTText>
                    <ToggleSwitch
                        enabled={userPreferences.testFail}
                        target={'testFail'}
                        handleChange={handleChange}
                    />
                </LTBox>
                <LTBox
                    width={'100%'}
                    justifyContent={'space-between'}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <LTText fontSize={14}>Test Pass messages</LTText>
                    <ToggleSwitch
                        enabled={userPreferences.testPass}
                        target={'testPass'}
                        handleChange={handleChange}
                    />
                </LTBox>
                <LTBox
                    width={'100%'}
                    justifyContent={'space-between'}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <LTText fontSize={14}>Notification Time</LTText>
                    <ActionMenu
                        open={open}
                        onOpenChange={(o) => setOpen(o)}
                    >
                        <ActionMenu.Button size='small'>
                            {
                                notificationOptions.find(
                                    (option) =>
                                        option.value ===
                                        userPreferences.buildNotificationTime
                                )?.label
                            }
                        </ActionMenu.Button>
                        <ActionMenu.Overlay
                            width='small'
                            sx={{
                                minWidth: '80px',
                                width: '160px',
                            }}
                            align='end'
                        >
                            <ActionList>
                                {notificationOptions.map((data, index) => {
                                    return (
                                        <ActionList.Item
                                            key={index}
                                            sx={{
                                                height: '24px',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                setOpen(false);
                                                handleChange(
                                                    'buildNotificationTime',
                                                    data.value
                                                );
                                            }}
                                        >
                                            {data.label}
                                        </ActionList.Item>
                                    );
                                })}
                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>
                </LTBox>
            </LTBox>
        </LTBox>
    );
}

type Callback<T> = (value?: T) => void;
type DispatchWithCallback<T> = (value: T, callback?: Callback<T>) => void;

function useStateCallback<T>(
    initialState: T | (() => T)
): [T, DispatchWithCallback<SetStateAction<T>>] {
    const [state, _setState] = useState(initialState);

    const callbackRef = useRef<Callback<T>>();
    const isFirstCallbackCall = useRef<boolean>(true);

    const setState = useCallback(
        (setStateAction: SetStateAction<T>, callback?: Callback<T>): void => {
            callbackRef.current = callback;
            _setState(setStateAction);
        },
        []
    );

    useEffect(() => {
        if (isFirstCallbackCall.current) {
            isFirstCallbackCall.current = false;
            return;
        }
        callbackRef.current?.(state);
    }, [state]);

    return [state, setState];
}
