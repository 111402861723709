// @ts-nocheck
import { toast, ToastOptions } from 'react-toastify';
import './index.css';
import { Box, Octicon, Text } from '@primer/react';
import { CheckIcon, StopIcon, XIcon } from '@primer/octicons-react';
import { LTToast, LTText, LTBox } from '@lambdatestincprivate/lt-components';

const ToasterContent = ({
    message,
    type,
}: {
    message: string;
    type: string;
}) => {
    return (
        <LTBox display={'flex'}>
            <LTBox
                sx={{
                    backgroundColor:
                        type === 'error' ? 'danger' : 'bg.successEmphasis',
                }}
                paddingX={'18px'}
                paddingY={'16px'}
                display={'flex'}
                alignItems={'center'}
            >
                <Octicon
                    icon={type === 'error' ? StopIcon : CheckIcon}
                    size={16}
                />
            </LTBox>
            <LTText
                color={'default'}
                fontSize={14}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingX: '16px',
                    paddingY: '12px',
                }}
            >
                {message}
            </LTText>
        </LTBox>
    );
};


const Toaster = {
    error: (message: string) => {
        const { addToast = () => {}, extendDuration = false } = window.toast || {};
        addToast({
            message,
            variant: "danger",
            extendDuration
        });
    },
    success: (message: string) => {
        const { addToast = () => {}, extendDuration = false } = window.toast || {};
        addToast({
            message,
            variant: "success",
            extendDuration
        });
    },
    info: (message: string) => {
        const { addToast = () => {}, extendDuration = false } = window.toast || {};
        addToast({
            message,
            variant: "default",
            extendDuration
        });
    },
};

export default Toaster;
