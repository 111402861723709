import { useState } from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { EventNames } from '../../../utils/events';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
} from '@primer/react';
import { LTButton, LTFormControl, LTText } from '@lambdatestincprivate/lt-components';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

export default function Bugasura() {
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [isLoading, setIsLoading] = useState(false);
    const [api_key, setApiKey] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                api_key,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <LTFormControl
                    label='Bugasura API Key'
                    labelStyle={{
                        fontSize: 12,
                    }}
                    formStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <LTTextInput
                        type='password'
                        aria-label='Bugasura API Key'
                        id='token'
                        width={'100%'}
                        contrast
                        placeholder=''
                        onChange={(value :any) => setApiKey(value)}
                        value={api_key}
                        inputOnly={true}
                        required
                    />

                    {(errors as any).api_key && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).api_key &&
                                (errors as any).api_key[0]}
                        </small>
                    )}
                </LTFormControl>
                <LTButton
                    variant={LTButton.Variant.PRIMARY}
                    disabled={isLoading}
                    onClick={install}
                    className='self-start'
                >
                    Install
                </LTButton>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'muted'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/bugasura-integration/'
                    target='_blank'
                    rel='noreferrer'
                >
                    API Key
                </Link>
            </div>
        </LeftPanel>
    );
}
