import { ReactNode } from 'react';

export const LeftPanel = ({ children }: { children: ReactNode }) => {
    return (
        <div className='w-[342px] border-r'>
            <div
                className='p-6 text-xs tracking-[0.5px] min-w-[342px] overflow-y-scroll'
                style={{ height: 'calc(100% - 56px)' }}
            >
                {children}
            </div>
        </div>
    );
};

export const Label = ({ children }: { children: ReactNode }) => {
    return <span className='text-neutral-500'>{children}</span>;
};

export const ToggleSwitch = ({
    enabled,
    target,
    handleChange,
}: {
    enabled: boolean;
    target: string;
    handleChange: (target: string, value: boolean) => void;
}) => {
    return (
        <label className='inline-flex relative items-center cursor-pointer'>
            <input
                type='checkbox'
                className='sr-only peer'
                checked={enabled}
                readOnly
            />
            <div
                onClick={() => {
                    handleChange(target, !enabled);
                }}
                className="w-6 h-3.5 bg-stone-300 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border-1  after:rounded-full after:h-[10px] after:w-[10px] after:transition-all peer-checked:bg-neutral-900"
            ></div>
        </label>
    );
};
