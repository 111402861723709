import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LeftPanel } from '../../Wrappers';
import { setInstructionState } from '../../../redux/features/configSlice';
import { RootState } from '../../../redux/store';
import {
    sanitizeAndRedirect,
    sendAmplitudeEvents,
    updatePluginList,
} from '../../../utils/helper';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import Icons from '../../../assets/images';
import { EventNames } from '../../../utils/events';
import Select from 'react-select';
import { customStyles } from '../../../assets/constants/constants';

export default function AzureDevOps() {
    const dispatch = useDispatch();
    const [instanceType, setInstanceType] = useState('cloud');
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const azureDevOpsOptions = useSelector(
        (state: RootState) => state.config.azureDevOpsOptions
    );
    const [isLoading, setIsLoading] = useState(false);
    const [host, setHost] = useState('');
    const [token, setToken] = useState('');
    const [org, setOrg] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        const data: any = {};
        if (azureDevOpsOptions && azureDevOpsOptions.length > 0) {
            data['org'] = org;
        } else {
            data['instance_type'] =
                instanceType === 'cloud' ? 'Cloud' : 'SelfHosted';
            data['host'] = host;
            data['token'] = token;
        }

        try {
            const res = await Api.installApi.commonInstall(
                sideBarObject.code,
                data
            );

            if (instanceType === 'cloud') {
                sanitizeAndRedirect(res.url);
            }

            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const isOrgSelected = () => {
        return !(
            azureDevOpsOptions &&
            azureDevOpsOptions.length > 0 &&
            org.length === 0
        );
    };

    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                {azureDevOpsOptions && azureDevOpsOptions.length > 0 ? (
                    <div className='flex flex-col space-y-2'>
                        <Label>Select your Organization</Label>
                        <Select
                            isClearable={false}
                            isSearchable={true}
                            options={azureDevOpsOptions?.map((elem) => {
                                return {
                                    label: elem.option,
                                    value: elem.value,
                                };
                            })}
                            onChange={(e) => setOrg(e?.value!)}
                            styles={customStyles}
                            value={azureDevOpsOptions
                                .map((elem) => {
                                    return {
                                        label: elem.option,
                                        value: elem.value,
                                    };
                                })
                                .find((elem) => elem.value === org)}
                        />
                    </div>
                ) : (
                    <>
                        <div className='flex flex-col space-y-2'>
                            <Label>Instance</Label>
                            <div className='flex flex-row space-x-5'>
                                <div className='flex flex-row space-x-1 items-center'>
                                    <input
                                        type='radio'
                                        aria-label='InputRadio'
                                        checked={instanceType === 'cloud'}
                                        readOnly
                                        onClick={() => {
                                            setInstanceType('cloud');
                                            dispatch(
                                                setInstructionState(
                                                    'Instructions'
                                                )
                                            );
                                        }}
                                    />
                                    <span>Cloud</span>
                                </div>
                                <div
                                    className='flex flex-row space-x-1 items-center'
                                    onClick={() => {
                                        setInstanceType('self_hosted');
                                        dispatch(
                                            setInstructionState(
                                                'Instructions(SelfHosted)'
                                            )
                                        );
                                    }}
                                >
                                    <input
                                        type='radio'
                                        aria-label='InputRadio'
                                        checked={instanceType === 'self_hosted'}
                                        readOnly
                                        onClick={() => {
                                            setInstanceType('cloud');
                                            dispatch(
                                                setInstructionState(
                                                    'Instructions'
                                                )
                                            );
                                        }}
                                    />
                                    <span>Self Hosted</span>
                                </div>
                            </div>
                        </div>
                        {instanceType === 'self_hosted' && (
                            <>
                                <div className='flex flex-col space-y-2'>
                                    <Label>Azure Devops URL</Label>
                                    <div className='flex border-1  border-gray-300 rounded-sm'>
                                        <input
                                            type='text'
                                            id='url'
                                            aria-label='Url'
                                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                            placeholder={
                                                'https://dev.azure.com/lambdatest'
                                            }
                                            onChange={(e) =>
                                                setHost(e.target.value)
                                            }
                                            value={host}
                                            required
                                        />
                                    </div>
                                    {(errors as any).host && (
                                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                                            {(errors as any).host &&
                                                (errors as any).host[0]}
                                        </small>
                                    )}
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <Label>Access Token</Label>
                                    <div className='flex border-1  border-gray-300 rounded-sm'>
                                        <input
                                            type='password'
                                            aria-label='Password'
                                            id='token'
                                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                                            placeholder='Please Enter Your Access Token'
                                            onChange={(e) =>
                                                setToken(e.target.value)
                                            }
                                            value={token}
                                            required
                                        />
                                    </div>
                                    {(errors as any).token && (
                                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                                            {(errors as any).token &&
                                                (errors as any).token[0]}
                                        </small>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                    disabled={!isOrgSelected()}
                >
                    {isLoading && (
                        <img
                            width='14px'
                            src={Icons.loaderGif}
                            alt='loader'
                            className='mr-1'
                        />
                    )}
                    Install
                </button>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'>
                <Label>Know how to generate</Label>
                <a
                    href='https://www.lambdatest.com/support/docs/vsts-integration/'
                    target='_blank'
                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                    rel='noreferrer'
                >
                    Personal Access Token
                </a>
            </div>
        </LeftPanel>
    );
}
