export interface IMyListItem {
    id: number;
    name: string;
    code: string;
    description: string;
    logo: string;
    is_oauth: number;
    disabled_logo: string;
    is_active: number;
    is_published: number;
    category: string;
    is_notifier: number;
    created_at: string;
    updated_at: string | null;
}

export interface IListItem {
    id: number;
    name: string;
    code: string;
    description: string;
    logo: string;
    is_oauth: number;
    disabled_logo: string | null;
    is_active: number;
    is_published: number;
    category: string;
    is_notifier: number;
    created_at: string;
    updated_at: string | null;
    is_installed: boolean;
}

export interface IPreferences {
    screenshotTestCompletion: boolean;
    buildCompletion: boolean;
    testFail: boolean;
    DailyTestReportAutomation: boolean;
    buildNotificationTime: number;
    testPass: boolean;
}

export interface IList {
    ['MY INTEGRATIONS']: IMyListItem[],
    ['BUG TRACKER']: IListItem[],
    ['COMMUNICATION']: IListItem[],
    ['PROJECT MANAGEMENT']: IListItem[],
    ['PLUGINS AND EXTENSIONS']: IListItem[],
    ['CI CD TOOLS']: IListItem[],
    ['CODELESS AUTOMATION PLATFORM']: IListItem[],
    ['CODELESS AUTOMATION TOOLS']: IListItem[],
    ['TEST REPORTING TOOLS']: IListItem[],
    ['3RD PARTY TOOLS']: IListItem[],
    ['ANALYTICS']: IListItem[],
}

export const globalList: IList = {
    "MY INTEGRATIONS": [],
    "BUG TRACKER": [],
    "COMMUNICATION": [],
    "PROJECT MANAGEMENT": [],
    "PLUGINS AND EXTENSIONS": [],
    "CI CD TOOLS": [],
    "CODELESS AUTOMATION PLATFORM": [],
    "CODELESS AUTOMATION TOOLS": [],
    "TEST REPORTING TOOLS": [],
    "3RD PARTY TOOLS": [],
    "ANALYTICS": []
}

export const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#fff',
        minHeight: '32px',
        height: '32px',
        boxShadow: state.isFocused ? null : null,
        fontSize: '12px',
        borderRadius: '2px',
        border: '1px solid #EAEAEA',
        '&:hover': {
            border: '1px solid #EAEAEA'
        }
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        height: '32px',
    }),
    input: (provided: any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: () => ({
        display: 'none',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        height: 28,
        padding: '4px 12px',
        fontSize: 12,
        color: '#333',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        backgroundColor: state.isSelected ? 'white' : 'white',
        '&:hover': {
            background: '#EAEAEA',
            cursor: 'pointer'
        }
    }),
};

export const routeCodes: string[] = [
    'jira',            'azure-devops',   'bugherd',
    'mantis',          'zipboard',       'airtable',
    'youtrack',        'linear-app',     'bugzilla',
    'pagerduty',       'bugsnag',        'goodday',
    'bugasura',        'devrev',         'fog-bugz',
    'microsoft-teams', 'rocket-chat',    'mattermost',
    'flock',           'google-chat',    'user-webhooks',
    'zapier',          'github',         'gitlab',
    'bitbucket',       'paymo',          'teamwork',
    'hive',            'shortcut',       'monday',
    'breeze',          'backlog',        'pivotal-tracker',
    'practitest',      'target-process', 'favro',
    'redmine',         'sumo-logic',     'zohobugtracker',
    'slack',           'asana',          'trello',
    'clickup',         'wrike',          'datadog',
    'notion',          'miro',           'bitbucket',
    'webex',           'zoho-cliq',      'airbrake',
    'userback',        'axosoft' ,       'zenkit',
    'new-relic',       'spirateam',      'copilot'
];

export const oauthCallbackRoutes: string[] = [
    'zohobugtracker',
    'slack',
    'asana',
    'trello',
    'clickup',
    'wrike',
    'datadog',
    'notion',
    'miro',
    'bitbucket',
    'github',
    'gitlab',
    'google-chat',
    'webex',
    'zoho-cliq',
    'azure-devops',
    'zenkit',
    'jira',
    'copilot'
];

export const newUIOnlyCodes: string[] = [
    'spirateam'
]

export const oauthDocumentationLink = {
    'zenkit': 'https://www.lambdatest.com/support/docs/zenkit-integration'
}

export const webhookCode = 'user-webhooks';
export const jiraCode = 'jira';
export const copilotCode = 'copilot';
