import { useState } from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../../assets/images';
import { RootState } from '../../../redux/store';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import { sendAmplitudeEvents, updatePluginList } from '../../../utils/helper';
import { EventNames } from '../../../utils/events';
import Select from 'react-select';
import { customStyles } from '../../../assets/constants/constants';

const newRelicOptions = [
    {
        label: 'US',
        value: 'US',
    },
    {
        label: 'EU',
        value: 'EU',
    },
    {
        label: 'FedRAMP',
        value: 'FedRAMP',
    },
];

export default function NewRelic() {
    const sideBarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const [isLoading, setIsLoading] = useState(false);
    const [licenseKey, setLicenseKey] = useState('');
    const [region, setRegion] = useState('');
    const [errors, setErrors] = useState({});

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sideBarObject.category,
            plugin_name: sideBarObject.name,
        });
        try {
            const res = await Api.installApi.commonInstall(sideBarObject.code, {
                licenseKey,
                region,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            await updatePluginList();
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                Toaster.error(err.response.data.message);
            }
        }
    };
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={install}
            >
                <div className='flex flex-col space-y-2'>
                    <Label>License Key/Api key</Label>
                    <div className='flex border border-gray-200 rounded-sm'>
                        <input
                            type='text'
                            id='licenseKey'
                            className='placeholder-stone-500 w-80 focus:outline-none focus:border-px h-8 px-2'
                            placeholder=''
                            value={licenseKey}
                            onChange={(e) => setLicenseKey(e.target.value)}
                            required
                        />
                    </div>
                    {(errors as any).licenseKey && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).licenseKey &&
                                (errors as any).licenseKey[0]}
                        </small>
                    )}
                </div>
                <div className='flex flex-col space-y-2'>
                    <Label>Region (US, EU, FedRAMP)</Label>
                    <Select
                        isClearable={false}
                        isSearchable={true}
                        options={newRelicOptions}
                        onChange={(e) => setRegion(e?.value!)}
                        styles={customStyles}
                        value={newRelicOptions.find(
                            (elem) => elem.value === region
                        )}
                    />
                    {(errors as any).region && (
                        <small className='w-full h-2.5 text-[10px] text-red-600'>
                            {(errors as any).region &&
                                (errors as any).region[0]}
                        </small>
                    )}
                </div>
                <button
                    type='submit'
                    form='form1'
                    className='py-1.5 left-0 bg-neutral-900 px-3 self-start rounded-sm hover:bg-neutral-800 text-white flex items-center'
                >
                    {isLoading && (
                        <img
                            width='14px'
                            src={Icons.loaderGif}
                            alt='loader'
                            className='mr-1'
                        />
                    )}
                    Install
                </button>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-6'>
                <Label>Know how to generate</Label>
                <a
                    href='https://www.lambdatest.com/support/docs/new-relic-integration/'
                    target='_blank'
                    className='text-zinc-800 hover:text-zinc-700 no-underline'
                    rel='noreferrer'
                >
                    Personal Access Token
                </a>
            </div>
        </LeftPanel>
    );
}
