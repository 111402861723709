import {
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../assets/images';
import {
    setPreferences,
    setSideBarObject,
} from '../redux/features/configSlice';
import { RootState } from '../redux/store';
import Api from '../utils/api';
import { updatePluginList } from '../utils/helper';
import Toaster from './Toaster/Index';
import { ToggleSwitch } from './Wrappers';
import { jiraCode, webhookCode, copilotCode } from '../assets/constants/constants';

interface IUserPreferences {
    screenshotTestCompletion: boolean;
    buildCompletion: boolean;
    testFail: boolean;
    testPass: boolean;
    buildNotificationTime: number;
}

function useOutsideAlerter(
    ref: any,
    setShowSettings: React.Dispatch<React.SetStateAction<boolean>>
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowSettings(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setShowSettings]);
}

const notificationOptions = [
    { label: '1 Minute', value: 60 },
    { label: '2 Minutes', value: 120 },
    { label: '3 Minutes', value: 180 },
    { label: '4 Minutes', value: 240 },
    { label: '5 Minutes', value: 300 },
    { label: '6 Minutes', value: 360 },
    { label: '7 Minutes', value: 420 },
    { label: '8 Minutes', value: 480 },
    { label: '9 Minutes', value: 540 },
    { label: '10 Minutes', value: 600 },
    { label: '11 Minutes', value: 660 },
    { label: '12 Minutes', value: 720 },
    { label: '13 Minutes', value: 780 },
    { label: '14 Minutes', value: 840 },
    { label: '15 Minutes', value: 900 },
];

export function InstalledCard({
    name,
    logo,
    code,
    category,
}: {
    name: string;
    logo: string;
    code: string;
    category: string;
}) {
    const wrapperRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    useOutsideAlerter(wrapperRef, setShowSettings);
    const [userPreferences, setUserPreferences] =
        useStateCallback<IUserPreferences>({
            screenshotTestCompletion: false,
            buildCompletion: false,
            testFail: false,
            testPass: false,
            buildNotificationTime: 900,
        });
    const [syncLoader, setSyncLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);

    const preferences = useSelector(
        (state: RootState) => state.config.preferences
    );

    const dispatch = useDispatch();

    useEffect(() => {
        setUserPreferences({
            screenshotTestCompletion: preferences.screenshotTestCompletion,
            buildCompletion: preferences.buildCompletion,
            testFail: preferences.testFail,
            testPass: preferences.testPass,
            buildNotificationTime: preferences.buildNotificationTime,
        });
    }, [preferences, setUserPreferences]);

    const handleChange = (target: string, value: boolean | number) => {
        setUserPreferences(
            { ...userPreferences, [target]: value },
            (userPref) => {
                handlePreferences(userPref, target);
            }
        );
    };

    const handlePreferences = async (
        userPref: SetStateAction<IUserPreferences> | undefined,
        target: string
    ) => {
        const newPreferences = {
            ...preferences,
            ...userPref,
        };
        try {
            let res;
            if (target === 'buildNotificationTime') {
                res = await Api.commonApi.setBuildNotificationTime(
                    newPreferences.buildNotificationTime
                );
            } else {
                res = await Api.commonApi.updatePreferences(newPreferences);
            }
            if (res.message) {
                Toaster.success(res.message);
            }
            dispatch(setPreferences(newPreferences));
        } catch (err: any) {
            if (err.response && err.response.data)
                Toaster.error(err.response.data.message);
        }
    };

    const handleRemove = async (e: React.ChangeEvent<any>) => {
        setRemoveLoader(true);
        try {
            const res = await Api.commonApi.removePlugin(code);
            if (res.message) {
                Toaster.success(res.message);
            }
            setRemoveLoader(false);
            await updatePluginList();
        } catch (err: any) {
            setRemoveLoader(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    const handleSync = async (e: React.ChangeEvent<any>) => {
        if (code === webhookCode || code === jiraCode) {
            dispatch(
                setSideBarObject({
                    name,
                    logo,
                    code,
                    category,
                    is_installed: true,
                })
            );
            return;
        }
        setSyncLoader(true);
        try {
            const res = await Api.commonApi.syncPlugin(code);
            if (res.message) {
                Toaster.success(res.message);
            }
            setSyncLoader(false);
            if (res.url) {
                window.location.href = res.url;
                return;
            }
            await updatePluginList();
        } catch (err: any) {
            setSyncLoader(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    return (
        <div className='relative flex w-full h-full rounded-md cursor-pointer border-2 align-items-center'>
            <div className='col-6 justify-center flex flex-col'>
                <img
                    className='w-12 h-12 self-center'
                    src={logo}
                    alt=''
                    role='presentation'
                />
                <span className='text-xs text-center w-full tracking-[.125em] uppercase mt-3'>
                    {name}
                </span>
            </div>
            <div className='col-6 pr-0 justify-center grid text-[13px]'>
                {category === 'COMMUNICATION' && code !== copilotCode && (
                    <>
                        <button
                            className='my-[5px] py-1.5 px-4 hover:bg-gray-100 rounded-[50px]'
                            onClick={() => setShowSettings(true)}
                            aria-label='Settings'
                        >
                            <img
                                src={Icons.settingsIcon}
                                alt='Settings-icon'
                                className='inline-block mr-2 w-3 h-3'
                                role='presentation'
                            />
                            Settings
                        </button>
                        {showSettings && (
                            <div
                                className='w-[352px] pt-2 p-3 h-64 absolute z-10 border-[1px] rounded bg-white border-neutral-200 shadow-[-8px_1px_35px_#e0e0e0] text-xs cursor-auto top-0 translate-x-32'
                                ref={wrapperRef}
                            >
                                <div className='w-2.5 h-2.5 bg-white absolute top-[22px] left-[-5px] border-[1px] border-neutral-200 border-r-0 border-t-0 rotate-45'></div>
                                <div className='text-sm h-10 border-b-[1px] border-neutral-200 flex justify-between items-center text-black'>
                                    Notification Preferences
                                    <div
                                        className='cursor-pointer rotate-45 text-lg text-zinc-700'
                                        onClick={() => setShowSettings(false)}
                                    >
                                        +
                                    </div>
                                </div>
                                <div className='flex flex-col space-y-5 text-neutral-800 pt-3'>
                                    {code !== webhookCode && (
                                        <div className='flex justify-between items-center'>
                                            Screenshot test completion messages
                                            <ToggleSwitch
                                                enabled={
                                                    userPreferences.screenshotTestCompletion
                                                }
                                                target={
                                                    'screenshotTestCompletion'
                                                }
                                                handleChange={handleChange}
                                            />
                                        </div>
                                    )}
                                    <div className='flex justify-between items-center'>
                                        Build completion messages
                                        <ToggleSwitch
                                            enabled={
                                                userPreferences.buildCompletion
                                            }
                                            target={'buildCompletion'}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        Test Fail messages
                                        <ToggleSwitch
                                            enabled={userPreferences.testFail}
                                            target={'testFail'}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        Test Pass messages
                                        <ToggleSwitch
                                            enabled={userPreferences.testPass}
                                            target={'testPass'}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        Notification Time
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                id='dropdown-basic'
                                                bsPrefix='w-44 h-8 border-[1px] border-gray-300 px-2.5 py-1.5 rounded-sm flex justify-between items-center'
                                                as={'button'}
                                            >
                                                {
                                                    notificationOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            userPreferences.buildNotificationTime
                                                    )?.label
                                                }
                                                <i className='fa fa-angle-down'></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                style={{
                                                    height: '176px',
                                                    overflowY: 'scroll',
                                                    borderRadius: '2px',
                                                    border: '1px solid #EAEAEA',
                                                }}
                                            >
                                                {notificationOptions.map(
                                                    (data, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                style={{
                                                                    height: '32px',
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    fontSize:
                                                                        '12px',
                                                                    paddingLeft:
                                                                        '12px',
                                                                }}
                                                                onClick={() => {
                                                                    handleChange(
                                                                        'buildNotificationTime',
                                                                        data.value
                                                                    );
                                                                }}
                                                            >
                                                                {data.label}
                                                            </Dropdown.Item>
                                                        );
                                                    }
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <button
                    className='my-[5px] py-1.5 px-4 hover:bg-gray-100 rounded-[50px]'
                    onClick={handleSync}
                    aria-label={code === webhookCode ? 'Update' : 'Resync'}
                >
                    <img
                        src={
                            syncLoader ? Icons.blackLoaderGif : Icons.resyncIcon
                        }
                        alt='Settings'
                        className='inline-block mr-2 w-3 h-3'
                        role='presentation'
                    />
                    {code === webhookCode ? 'Update' : 'Resync'}
                </button>
                {code !== webhookCode && (
                    <button
                        className='my-[5px] py-1.5 px-4 hover:bg-gray-100 rounded-[50px]'
                        onClick={handleRemove}
                        aria-label='Remove'
                    >
                        <img
                            src={
                                removeLoader
                                    ? Icons.blackLoaderGif
                                    : Icons.removeIcon
                            }
                            alt='Settings'
                            className='inline-block mr-2 w-3 h-3'
                            role='presentation'
                        />
                        Remove
                    </button>
                )}
            </div>
        </div>
    );
}

type Callback<T> = (value?: T) => void;
type DispatchWithCallback<T> = (value: T, callback?: Callback<T>) => void;

function useStateCallback<T>(
    initialState: T | (() => T)
): [T, DispatchWithCallback<SetStateAction<T>>] {
    const [state, _setState] = useState(initialState);

    const callbackRef = useRef<Callback<T>>();
    const isFirstCallbackCall = useRef<boolean>(true);

    const setState = useCallback(
        (setStateAction: SetStateAction<T>, callback?: Callback<T>): void => {
            callbackRef.current = callback;
            _setState(setStateAction);
        },
        []
    );

    useEffect(() => {
        if (isFirstCallbackCall.current) {
            isFirstCallbackCall.current = false;
            return;
        }
        callbackRef.current?.(state);
    }, [state]);

    return [state, setState];
}
