export default function DynamicLoader({ component }: { component: string }) {
    const COMPONENTS: { [index: string]: any } = {
        Jira: require('./Integrations/Jira/Index'),
        InstructionsPanel: require('./InstructionsPanel'),
        Airbrake: require('./Integrations/Airbrake/Index'),
        Airtable: require('./Integrations/Airtable/Index'),
        Axosoft: require('./Integrations/Axosoft/Index'),
        Backlog: require('./Integrations/Backlog/Index'),
        AzureDevOps: require('./Integrations/AzureDevOps/Index'),
        Breeze: require('./Integrations/Breeze/Index'),
        Bugasura: require('./Integrations/Bugasura/Index'),
        Bugherd: require('./Integrations/Bugherd/Index'),
        Bugsnag: require('./Integrations/Bugsnag/Index'),
        Bugzilla: require('./Integrations/Bugzilla/Index'),
        DevRev: require('./Integrations/DevRev/Index'),
        Favro: require('./Integrations/Favro/Index'),
        Flock: require('./Integrations/Flock/Index'),
        FogBugz: require('./Integrations/FogBugz/Index'),
        Github: require('./Integrations/Github/Index'),
        Gitlab: require('./Integrations/Gitlab/Index'),
        GoodDay: require('./Integrations/GoodDay'),
        GoogleChat: require('./Integrations/GoogleChat/Index'),
        Hive: require('./Integrations/Hive/Index'),
        LinearApp: require('./Integrations/LinearApp/Index'),
        Mantis: require('./Integrations/Mantis/Index'),
        Mattermost: require('./Integrations/Mattermost/Index'),
        MicrosoftTeams: require('./Integrations/MicrosoftTeams/Index'),
        Monday: require('./Integrations/Monday/Index'),
        NewRelic: require('./Integrations/NewRelic/Index'),
        PagerDuty: require('./Integrations/PagerDuty/Index'),
        Paymo: require('./Integrations/Paymo/Index'),
        PivotalTracker: require('./Integrations/PivotalTracker/Index'),
        Practitest: require('./Integrations/Practitest/Index'),
        Redmine: require('./Integrations/Redmine/Index'),
        RocketChat: require('./Integrations/RocketChat/Index'),
        Shopify: require('./Integrations/Shopify/Index'),
        Shortcut: require('./Integrations/Shortcut/Index'),
        SumoLogic: require('./Integrations/SumoLogic/Index'),
        TargetProcess: require('./Integrations/TargetProcess/Index'),
        Teamwork: require('./Integrations/Teamwork/Index'),
        Userback: require('./Integrations/Userback/Index'),
        Webhooks: require('./Integrations/Webhooks/Index'),
        Webex: require('./Integrations/Webex/Index'),
        YouTrack: require('./Integrations/YouTrack'),
        Zipboard: require('./Integrations/Zipboard/Index'),
        ZohoCliq: require('./Integrations/ZohoCliq/Index'),
    };
    if (component && COMPONENTS[component]) {
        let Component = COMPONENTS[component].default;
        return <Component />;
    } else {
        return <></>;
    }
}
