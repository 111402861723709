import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import {
    setInstructionState,
    setSideBarObject,
} from '../redux/features/configSlice';
import InstructionsPanel from './InstructionsPanel';
import DynamicLoader from './DynamicLoader';
import { useState } from 'react';
import { Label, LeftPanel } from './Wrappers';
import Icons from '../assets/images';
import Api from '../utils/api';
import Toaster from './Toaster/Index';
import GoogleButton from 'react-google-button';
import { EventNames } from '../utils/events';
import { sendAmplitudeEvents, updatePluginList } from '../utils/helper';
import {
    oauthDocumentationLink,
    webhookCode,
} from '../assets/constants/constants';
import {
    Box,
    Heading,
    IconButton,
    Octicon,
    Text,
    Button,
    Link,
} from '@primer/react';
import { XIcon, DotFillIcon } from '@primer/octicons-react';
import { Settings } from './Settings';
import { LTBox, LTButton, LTIconButton, LTText } from '@lambdatestincprivate/lt-components';

export default function Sidebar() {
    const [removeLoading, setRemoveLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const sidebarObject: any = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const googleChatOptions = useSelector(
        (state: RootState) => state.config.googleChatOptions
    );
    const webexOptions = useSelector(
        (state: RootState) => state.config.webexOptions
    );
    const zohoCliqOptions = useSelector(
        (state: RootState) => state.config.zohoCliqOptions
    );
    const showSettings = useSelector(
        (state: RootState) => state.config.showSettings
    );

    const install = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        sendAmplitudeEvents(EventNames.INT_CLICK_INSTALL, {
            category: sidebarObject.category,
            plugin_name: sidebarObject.name,
        });
        try {
            const res = await Api.installApi.getOAuthURL(sidebarObject.code);
            if (res.url) {
                window.open(res.url, '_self');
            }
            setIsLoading(false);
        } catch (err: any) {
            Toaster.error(err.response.data.message);
            setIsLoading(false);
        }
    };
    const dispatch = useDispatch();

    const handleRemove = async () => {
        if (sidebarObject.is_installed === false) {
            return;
        }
        setRemoveLoading(true);
        try {
            const res = await Api.commonApi.removePlugin(sidebarObject.code);
            if (res.message) {
                Toaster.success(res.message);
            }
            await updatePluginList();
            setRemoveLoading(false);
        } catch (err: any) {
            setRemoveLoading(false);
            if (err.response.data.message) {
                Toaster.error(err.response.data.message);
            }
        }
    };

    return (
        <fieldset
            className='h-full tracking-[0.4px] max-[685px]:overflow-y-scroll'
            aria-label={sidebarObject.name + ' sidebar'}
        >
			<legend className="sr-only">
				{(showSettings ? 'Configure ' : 'Setup ') + sidebarObject.name}
			</legend>
            <LTBox
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '14px',
                    alignItems: 'center',
                }}
            >
                <Heading
                    as={'h2'}
                    sx={{ fontSize: '14px' }}
                >
                    {(showSettings ? 'Configure ' : 'Setup ') +
                        sidebarObject.name}
                </Heading>
                <LTIconButton
                    onClick={() => {
                        dispatch(setSideBarObject({}));
                        dispatch(setInstructionState('Instructions'));
                    }}
                    className='w-2.5 h-2.5'
                    ariaLabel='Close Sidebar'
                    icon={XIcon}
                    size={LTIconButton.Size.SMALL}
                    variant='invisible'
                />
            </LTBox>
            <LTBox
                p={16}
                borderY={1}
                borderColor={'border.default'}
                borderStyle={'solid'}
            >
                <div className='flex'>
                    <img
                        src={sidebarObject.logo}
                        alt={sidebarObject.name + ' logo'}
                        className='w-[48px] mr-6'
                    />
                    <LTBox width={'100%'}>
                        <LTBox
                            display={'flex'}
                            height={showSettings ? '50px' : 'auto'}
                            alignItems={'center'}
                            width={'100%'}
                            justifyContent={'space-between'}
                        >
                            <Heading
                                as={'h2'}
                                sx={{ fontSize: 20 }}
                            >
                                {sidebarObject.name}
                            </Heading>
                            {showSettings &&
                                sidebarObject.code !== webhookCode && (
                                    <LTButton
                                        size = {LTButton.Size.SMALL}
                                        variant={LTButton.Variant.DANGER}
                                        onClick={handleRemove}
                                        disabled={removeLoading}
                                    >
                                        Remove App
                                    </LTButton>
                                )}
                        </LTBox>
                        {!showSettings && (
                            <div className='flex text-sm mt-2 gap-2'>
                                <LTText color={'muted'}>Requirement</LTText>
                                <LTText fontWeight={'semibold'}>
                                    Admin/User Role
                                </LTText>
                                <Octicon
                                    sx={{ width: 10, alignSelf: 'center' }}
                                    icon={DotFillIcon}
                                    color='muted'
                                />
                                <LTText color={'muted'}>Setup time</LTText>
                                <LTText fontWeight={'semibold'}>
                                    About 3 minutes
                                </LTText>
                            </div>
                        )}
                    </LTBox>
                </div>
            </LTBox>
            {showSettings ? (
                <Settings plugin={sidebarObject.code} />
            ) : (
                <div
                    className='flex w-full h-full'
                    style={{ height: 'calc(100% - 112px)' }}
                >
                    {sidebarObject.is_oauth === 1 &&
                    !webexOptions &&
                    !googleChatOptions &&
                    !zohoCliqOptions ? (
                        <LeftPanel>
                            <form
                                action=''
                                className='flex flex-col space-y-4'
                                id='form1'
                                onSubmit={install}
                            >
                                {sidebarObject.code === 'google-chat' ? (
                                    <GoogleButton
                                        onClick={install}
                                        type='light'
                                        style={{
                                            transform: 'scale(0.8)',
                                            transformOrigin: 'left',
                                        }}
                                    />
                                ) : (
                                    <LTButton
                                        onClick={install}
                                        variant={LTButton.Variant.PRIMARY}
                                        disabled={isLoading}
                                        className='self-start'
                                    >
                                        Install
                                    </LTButton>
                                )}
                            </form>
                            {sidebarObject.code in oauthDocumentationLink && (
                                <div
                                    className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'
                                    aria-label='Help Group'
                                >
                                    <LTText color={'muted'}>
                                        Know how to generate
                                    </LTText>
                                    <Link
										className='focus-black'
                                        href={
                                            oauthDocumentationLink[
                                                sidebarObject.code as keyof typeof oauthDocumentationLink
                                            ]
                                        }
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Personal Access Token
                                    </Link>
                                </div>
                            )}
                        </LeftPanel>
                    ) : (
                        <DynamicLoader
                            component={sidebarObject.name.replace(/ /g, '')}
                        />
                    )}

                    <InstructionsPanel />
                </div>
            )}
        </fieldset>
    );
}
