import { useEffect, useRef, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IListItem } from '../assets/constants/constants';
import { LTBox, LTButton, LTFormControl, LTModal, LTNavList, LTText } from '@lambdatestincprivate/lt-components';
import {
    setWebexOptions,
    setZohoCliqOptions,
    setJiraOptions,
    setGoogleChatOptions,
    setInstructionState,
    setPlugins,
    setPreferences,
    setSideBarObject,
    setAzureDevOpsOptions,
    setShowSettings,
} from '../redux/features/configSlice';
import { RootState } from '../redux/store';
import Api from '../utils/api';
import {
    checkValidInput,
    filterInactivePlugins,
    redirectToLogin,
    searchCode,
    searchRequestedIntegration,
    sendAmplitudeEvents,
} from '../utils/helper';
import { Card } from './Card';
import Sidebar from './Sidebar';
import Toaster from './Toaster/Index';
import { useNavigate } from 'react-router-dom';
import { EventNames } from '../utils/events';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

const NavItems = [
    {
        contentId: "my-integrations",
        name: "my-integrations",
        displayName: "My Integrations",
        value: "my-integrations"
    },
    {
        contentId: "bug-tracker",
        name: "bug-tracker",
        displayName: "Bug Tracker",
        value: "bug-tracker"
    },
    {
        contentId: "project-management",
        name: "project-management",
        displayName: "Project Management",
        value: "project-management"
    },
    {
        contentId: "analytics",
        name: "analytics",
        displayName: "Analytics",
        value: "analytics"
    },
    {
        contentId: "communication",
        name: "communication",
        displayName: "Communication",
        value: "communication"
    },
    {
        contentId: "ci-cd-tools",
        name: "ci-cd-tools",
        displayName: "CI CD Tools",
        value: "ci-cd-tools"
    },
    {
        contentId: "codeless-automation-platform",
        name: "codeless-automation-platform",
        displayName: "Codeless Automation Platform",
        value: "codeless-automation-platform"
    },
    {
        contentId: "codeless-automation-tools",
        name: "codeless-automation-tools",
        displayName: "Codeless Automation Tools",
        value: "codeless-automation-tools"
    },
    {
        contentId: "test-reporting-tools",
        name: "test-reporting-tools",
        displayName: "Test Reporting Tools",
        value: "test-reporting-tools"
    },
    {
        contentId: "3rd-party-tools",
        name: "3rd-party-tools",
        displayName: "3rd Party Tools",
        value: "3rd-party-tools"
    },
    {
        contentId: "plugins-and-extensions",
        name: "plugins-and-extensions",
        displayName: "Plugins and Extensions",
        value: "plugins-and-extensions"
    }
];

function Home(): JSX.Element {
    const [myIntegrations, setMyIntegrations] = useState<IListItem[]>([]);
    const [activeSection, setActiveSection] = useState('bug-tracker');
    const [isLoading, setIsLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [requestIntegration, setRequestIntegration] = useState('');
    const [bugTracker, setBugTracker] = useState<IListItem[]>([]);
    const [projectManagement, setProjectManagement] = useState<IListItem[]>([]);
    const [analytics, setAnalytics] = useState<IListItem[]>([]);
    const [communication, setCommunication] = useState<IListItem[]>([]);
    const [cicdTools, setCicdTols] = useState<IListItem[]>([]);
    const [navItemsToRender, setNavItemsToRender] = useState(NavItems);
    const [codelessAutomationPlatform, setCodelessAutomationPlatform] =
        useState<IListItem[]>([]);
    const [codelessAutomationTools, setCodelessAutomationTools] = useState<
        IListItem[]
    >([]);
    const [testReporting, setTestReporting] = useState<IListItem[]>([]);
    const [thirdParty, setThirdParty] = useState<IListItem[]>([]);
    const [pluginsAndExtension, setPluginsAndExtension] = useState<IListItem[]>(
        []
    );

    const sideBarObject = useSelector(
        (state: RootState) => state.config.sideBarObject
    );
    const showSettings = useSelector(
        (state: RootState) => state.config.showSettings
    );
    const plugins = useSelector((state: RootState) => state.config.plugins);
    const [clicked, setClicked] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const innerRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        async function fetchList() {
            if (window.location.pathname.includes('/auth/callback')) {
                try {
                    const code = window.location.pathname.split('/')[1];
                    let queryString = window.location.search;
                    const hashString = window.location.hash;
                    if (hashString.length > 2) {
                        queryString = hashString.replaceAll('#', '?');
                    }
                    const res = await Api.installApi.oauthCallback(
                        code,
                        queryString
                    );
                    if (res.message) {
                        Toaster.success(res.message);
                    }
                    if (code === 'google-chat') {
                        dispatch(setGoogleChatOptions(res));
                        navigate('/google-chat/install');
                        return;
                    }
                    if (code === 'webex') {
                        dispatch(setWebexOptions(res));
                        navigate('/webex/install');
                        return;
                    }
                    if (code === 'azure-devops') {
                        dispatch(setAzureDevOpsOptions(res));
                        navigate('/azure-devops/install');
                        return;
                    }
                    if (code === 'zoho-cliq') {
                        dispatch(setZohoCliqOptions(res));
                        navigate('/zoho-cliq/install');
                        return;
                    }
                    if (code === 'jira') {
                        dispatch(setJiraOptions(res));
                        navigate('/jira/install');
                        return;
                    }
                } catch (err: any) {
                    if (err.response && err.response.data)
                        Toaster.error(err.response.data.message);
                }
                window.history.pushState({}, '', '/');
            }
            try {
                const list = await Api.commonApi.fetchList();
                dispatch(setPlugins(filterInactivePlugins(list)));
                if (list['MY INTEGRATIONS'].length > 0) {
                    setActiveSection('my-integrations');
                } else {
                    setNavItemsToRender(prevNavItemsToRender => {
                        const updatedNavItems = [...prevNavItemsToRender];
                        updatedNavItems.shift();
                        return updatedNavItems;
                    })
                }
                const res = await Api.commonApi.getPreferences();
                const buildNotificationApi =
                    await Api.commonApi.getBuildNotificationTime();
                if (
                    res.data &&
                    res.data.preferences &&
                    buildNotificationApi.build_notification_time
                ) {
                    dispatch(
                        setPreferences({
                            ...res.data.preferences,
                            buildNotificationTime:
                                buildNotificationApi.build_notification_time,
                        })
                    );
                }
                const sidebarObj = searchCode(list);
                if (Object.keys(sidebarObj).length > 0) {
                    if (!sidebarObj.is_installed) {
                        dispatch(setSideBarObject(sidebarObj));
                    } else {
                        window.history.pushState({}, '', '/');
                    }
                }
            } catch (err: any) {
                if (err.response && err.response.data)
                    Toaster.error(err.response.data.message);
                if (err.response && err.response.status === 401) {
                    redirectToLogin();
                }
            }
        }
        fetchList();
    }, [dispatch, navigate]);

    useEffect(() => {
        if (Object.keys(sideBarObject).length === 0) {
            setTimeout(() => {
                dispatch(setShowSettings(false));
            }, 1000);
        }
    }, [sideBarObject]);

    useEffect(() => {
        setMyIntegrations(
            plugins['MY INTEGRATIONS'].map((e) => {
                return { ...e, is_installed: true };
            })
        );
        setBugTracker(plugins['BUG TRACKER']);
        setProjectManagement(plugins['PROJECT MANAGEMENT']);
        setAnalytics(plugins['ANALYTICS']);
        setCommunication(plugins['COMMUNICATION']);
        setCicdTols(plugins['CI CD TOOLS']);
        setCodelessAutomationPlatform(plugins['CODELESS AUTOMATION PLATFORM']);
        setCodelessAutomationTools(plugins['CODELESS AUTOMATION TOOLS']);
        setTestReporting(plugins['TEST REPORTING TOOLS']);
        setThirdParty(plugins['3RD PARTY TOOLS']);
        setPluginsAndExtension(plugins['PLUGINS AND EXTENSIONS']);
    }, [plugins]);

    useEffect(() => {
        const mainBody = document.getElementById('main_body');
        mainBody?.scrollTo(0, mainBody.scrollHeight);
    }, [expand]);

    const searchList = (value: string) => {
        setMyIntegrations(
            plugins['MY INTEGRATIONS']
                .map((e) => {
                    return { ...e, is_installed: true };
                })
                .filter((element) =>
                    element.name
                        .toLowerCase()
                        .includes(value.toLowerCase())
                )
        );
        setBugTracker(
            plugins['BUG TRACKER'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setProjectManagement(
            plugins['PROJECT MANAGEMENT'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setAnalytics(
            plugins['ANALYTICS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setCommunication(
            plugins['COMMUNICATION'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setCicdTols(
            plugins['CI CD TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setCodelessAutomationPlatform(
            plugins['CODELESS AUTOMATION PLATFORM'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setCodelessAutomationTools(
            plugins['CODELESS AUTOMATION TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setTestReporting(
            plugins['TEST REPORTING TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setThirdParty(
            plugins['3RD PARTY TOOLS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setPluginsAndExtension(
            plugins['PLUGINS AND EXTENSIONS'].filter((element) =>
                element.name
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
    };

    const totalIntegrations = () => {
        let count = 0;
        count += plugins['BUG TRACKER'].length;
        count += plugins['PROJECT MANAGEMENT'].length;
        count += plugins['ANALYTICS'].length;
        count += plugins['COMMUNICATION'].length;
        count += plugins['CI CD TOOLS'].length;
        count += plugins['CODELESS AUTOMATION PLATFORM'].length;
        count += plugins['CODELESS AUTOMATION TOOLS'].length;
        count += plugins['TEST REPORTING TOOLS'].length;
        count += plugins['3RD PARTY TOOLS'].length;
        count += plugins['PLUGINS AND EXTENSIONS'].length;
        return count;
    };

    const cardButtonClick = async (element: IListItem) => {
        if (element.is_installed) {
            return;
        }
        sendAmplitudeEvents(EventNames.INT_SELECT_INTEGRATION, {
            category: element.category,
            plugin_name: element.name,
        });
        if (
            element.code.startsWith('https://') ||
            element.code.startsWith('http://')
        ) {
            window.open(element.code, '_blank')?.focus();
            return;
        } else {
            dispatch(setSideBarObject(element));
        }
    };

    const handleRequestPlugin = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (requestIntegration.length === 0) {
            return;
        }
        const sidebarObj = await searchRequestedIntegration(
            plugins,
            requestIntegration
        );
        if (sidebarObj && Object.keys(sidebarObj).length > 0) {
            Toaster.success('We already have this integration');
            if (
                !sidebarObj.is_installed &&
                !(
                    sidebarObj.code.startsWith('https://') ||
                    sidebarObj.code.startsWith('http://')
                )
            ) {
                dispatch(setSideBarObject(sidebarObj));
            }
            setRequestIntegration('');
            return;
        }
        setIsLoading(true);
        try {
            const res = await Api.commonApi.requestIntegration({
                integration: requestIntegration,
            });
            if (res.message) {
                Toaster.success(res.message);
            }
            setIsLoading(false);
            setExpand(false);
            setRequestIntegration('');
        } catch (err: any) {
            setIsLoading(false);
            setExpand(false);
            setRequestIntegration('');
            if (err.response && err.response.data)
                Toaster.error(err.response.data.message);
        }
    };

    useEffect(() => {
        const pluginContainer = document.getElementById('plugins');
        const handleScroll = () => {
            setClicked('');
            let scrollPosition = 0;
            const pluginContainer = document.getElementById('plugins');
            if (pluginContainer) {
                scrollPosition = pluginContainer.scrollTop;
            }

            if (scrollPosition <= 56) {
                const section = document.querySelector('section');
                setActiveSection(section?.id || '');
                return;
            }

            const sections = document.querySelectorAll('section');
            let currentActiveSection = '';

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (
                    scrollPosition + 69 >= sectionTop &&
                    scrollPosition < sectionTop + sectionHeight
                ) {
                    currentActiveSection = section.id;
                }
            });

            // Update the active section state
            setActiveSection(currentActiveSection);
        };

        if (pluginContainer) {
            pluginContainer.addEventListener('scroll', handleScroll);

            // Clean up the event listener on component unmount
            return () => {
                pluginContainer.removeEventListener('scroll', handleScroll);
            };
        }
        // Attach the scroll event listener
    }, []);

    const onNavClick = (id: string) => {
        console.log("onNavClick", id)
        setTimeout(() => {
            setClicked(id);
        }, 100);
    };

    const handleNavChange = (item: any) => {
        console.log("handleNavChange", item.contentId)
        setTimeout(() => {
            setClicked(item?.contentId);
        }, 100);
        if (item?.contentId) {
            window.location.href = `#${item.contentId}`;
        }
    }

    useEffect(() => {
        if (expand) {
            if (innerRef.current) innerRef.current.focus();
        }
    }, [expand]);

    const checkCurrent = (id: string) => {
        return clicked !== '' ? clicked === id : activeSection == id;
    };

    return (
        <>
            <main
                id='main_body'
                className='pr-6 pl-3 py-3 main_body flex gap-x-5 max-[685px]:flex-wrap max-[685px]:h-auto'
            >
                <div id='integration-categories-side-nav' className='h-full min-w-[240px] flex flex-col justify-between'>
                    <LTNavList
                        ariaLabel="Integration Categories"
                        listItems={navItemsToRender}
                        selectedTab={clicked !== '' ? clicked : activeSection}
                        handleSelectedTab={handleNavChange}
                        tabIdPrefix={`integration-categories`}
                        tabPanelId="integration-categories-side-nav"
                        extraStyles={{
                            marginTop: "-8px"
                        }}
                    />

                    <div className='text-xs mb-3 flex flex-col space-y'>
                        <LTText color={'#666666'}>
                            Couldn’t find what you’re looking for?
                        </LTText>
                        <a
                            className='cursor-pointer mt-2 font-semibold'
                            onClick={() => setExpand(true)}
                            href='#'
                        >
                            Request Integration
                        </a>
                    </div>
                </div>
                <div
                    className='h-full overflow-y-scroll w-full'
                    id='plugins'
                >
                    {totalIntegrations() > 0 && (
                        <LTTextInput
                            sx={{
                                width: '100%',
                                height: '30px',
                                marginBottom: '24px',
                            }}
                            placeholder={`Search from more than ${totalIntegrations()} apps`}
                            onChange={(value: any) => searchList(value)}
                            ariaLabel='Search Integrations'
                            trailingAction={() => searchList('')}
                        />
                    )}
                    <div className='row m-0'>
                        {myIntegrations.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-my-integrations'
                                className='p-0 mb-4'
                                id='my-integrations'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    My Integrations
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {myIntegrations.map((element, index) => {
                                        return (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {bugTracker.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-bug-tracker'
                                className='p-0 mb-4'
                                id='bug-tracker'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Bug Tracker
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {bugTracker.map((element, index) => {
                                        return element.is_published ? (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {projectManagement.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-project-management'
                                className='p-0 mb-4'
                                id='project-management'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Project Management
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {projectManagement.map((element, index) => {
                                        return element.is_published ? (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {analytics.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-analytics'
                                className='p-0 mb-4'
                                id='analytics'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Analytics
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {analytics.map((element, index) => {
                                        return element.is_published ? (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {communication.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-communication'
                                className='p-0 mb-4'
                                id='communication'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Communication
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {communication.map((element, index) => {
                                        return element.is_published ? (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {cicdTools.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-ci-cd-tools'
                                className='p-0 mb-4'
                                id='ci-cd-tools'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    CI CD Tools
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {cicdTools.map((element, index) => {
                                        return element.is_published ? (
                                            <Card
                                                element={element}
                                                installPlugin={cardButtonClick}
                                            />
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {codelessAutomationPlatform.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-codeless-automation-platform'
                                className='p-0 mb-4'
                                id='codeless-automation-platform'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Codeless Automation Platform
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {codelessAutomationPlatform.map(
                                        (element, index) => {
                                            return element.is_published ? (
                                                <li className='contents'>
                                                    <Card
                                                        element={element}
                                                        installPlugin={
                                                            cardButtonClick
                                                        }
                                                    />
                                                </li>
                                            ) : (
                                                ''
                                            );
                                        }
                                    )}
                                </ul>
                            </section>
                        )}
                        {codelessAutomationTools.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-codeless-automation-tools'
                                className='p-0 mb-4'
                                id='codeless-automation-tools'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Codeless Automation Tools
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {codelessAutomationTools.map(
                                        (element, index) => {
                                            return element.is_published ? (
                                                <li className='contents'>
                                                    <Card
                                                        element={element}
                                                        installPlugin={
                                                            cardButtonClick
                                                        }
                                                    />
                                                </li>
                                            ) : (
                                                ''
                                            );
                                        }
                                    )}
                                </ul>
                            </section>
                        )}
                        {testReporting.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-test-reporting-tools'
                                className='p-0 mb-4'
                                id='test-reporting-tools'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Test Reporting Tools
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {testReporting.map((element, index) => {
                                        return element.is_published ? (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {thirdParty.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-3rd-party-tools'
                                className='p-0 mb-4'
                                id='3rd-party-tools'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    3rd Party Tools
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {thirdParty.map((element, index) => {
                                        return element.is_published ? (
                                            <li className='contents'>
                                                <Card
                                                    element={element}
                                                    installPlugin={cardButtonClick}
                                                />
                                            </li>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </ul>
                            </section>
                        )}
                        {pluginsAndExtension.length > 0 && (
                            <section
                                role='tabpanel'
                                area-labelledby='integration-categories-side-nav-plugins-and-extensions'
                                className='p-0 mb-4'
                                id='plugins-and-extensions'
                            >
                                <LTText
                                    as='h2'
                                    sx={{ fontSize: 14 }}
                                >
                                    Plugins and Extensions
                                </LTText>
                                <ul className='grid grid-cols-3 gap-4 max-[1439px]:grid-cols-2 max-[909px]:grid-cols-1 list-none p-0'>
                                    {pluginsAndExtension.map(
                                        (element, index) => {
                                            return element.is_published ? (
                                                <li className='contents'>
                                                    <Card
                                                        element={element}
                                                        installPlugin={
                                                            cardButtonClick
                                                        }
                                                    />
                                                </li>
                                            ) : (
                                                ''
                                            );
                                        }
                                    )}
                                </ul>
                            </section>
                        )}
                    </div>
                </div>
                <Offcanvas
                    aria-label='Install Integration'
                    show={Object.keys(sideBarObject).length > 0}
                    onHide={() => {
                        dispatch(setInstructionState('Instructions'));
                        dispatch(setSideBarObject({}));
                    }}
                    placement='end'
                    style={{ width: showSettings ? '487px' : '766px' }}
                >
                    {Object.keys(sideBarObject).length > 0 && <Sidebar />}
                </Offcanvas>
                {/* {totalIntegrations() > 0 && (
                <form
                    className='w-full'
                    id='request'
                    onSubmit={handleRequestPlugin}
                >
                    {expand && (
                        <div className='flex gap-x-2'>
                            <label className='relative text-zinc-600 focus-within:text-gray-800 block'>
                                <img
                                    src={Icon.addIntegrationIcon}
                                    alt='Search Icon'
                                    className='pointer-events-none w-3 h-3 absolute top-1/2 transform -translate-y-1/2 left-3'
                                />
                                <input
                                    type='text'
                                    aria-label='InputText'
                                    placeholder='Request new integration here'
                                    className='border-1  border-gray-300 placeholder-neutral-400 w-96 pl-8 focus:outline-none h-8 rounded-sm text-xs'
                                    value={requestIntegration}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        if (
                                            !val.startsWith(' ') &&
                                            val.length < 51
                                        )
                                        setRequestIntegration(val);
                                    }}
                                />
                            </label>
                            <button
                                type='submit'
                                className={`py-1.5 px-3 rounded-sm flex items-center text-[10px] h-8 ${
                                    checkValidInput(requestIntegration)
                                        ? 'bg-neutral-900 text-white cursor-pointer'
                                        : 'bg-gray-200 text-zinc-800'
                                }`}
                                form='request'
                                disabled={!checkValidInput(requestIntegration)}
                            >
                                {isLoading && (
                                    <img
                                        width='14px'
                                        src={Icon.loaderGif}
                                        alt='loader'
                                        className='mr-1'
                                    />
                                )}
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            )} */}
            </main>
            <LTBox
                className='dialogBox'
                position={'absolute'}
            >
                <LTModal
                    isOpen={expand}
                    onClose={() => setExpand(false)}
                    ariaLabel='Request Integration'
                    title='Request New Integration'
                >
                    <LTBox>
                        <form
                            className='w-full'
                            id='request'
                            onSubmit={handleRequestPlugin}
                        >
                            <LTFormControl
                                label='Integration Name'
                                labelStyle={{
                                    fontSize: 14,
                                    marginBottom: '1px',
                                    fontWeight: 500,
                                }}
                                formStyles={{ height: '144px', padding: '16px' }}
                            >
                                <LTTextInput
                                    ref={innerRef}
                                    aria-label='Integration Name'
                                    placeholder='New Integration Name'
                                    value={requestIntegration}
                                    width={'100%'}
                                    contrast
                                    onChange={(value: any) => {
                                        if (
                                            !value.startsWith(' ') &&
                                            value.length < 51
                                        )
                                            setRequestIntegration(value);
                                    }}
                                    inputOnly={true}
                                />
                            </LTFormControl>
                            <LTBox
                                display={'flex'}
                                p={'16px'}
                                width={'100%'}
                                justifyContent={'end'}
                                sx={{ gap: '8px' }}
                                borderTop={1}
                                borderColor={'border.default'}
                                borderTopStyle={'solid'}
                            >
                                <LTButton
                                    onClick={() => {
                                        setExpand(false);
                                    }}
                                    size={LTButton.Size.MEDIUM}
                                >
                                    Cancel
                                </LTButton>
                                <LTButton
                                    variant={LTButton.Variant.PRIMARY}
                                    size={LTButton.Size.MEDIUM}
                                    onClick={handleRequestPlugin}
                                    disabled={
                                        !checkValidInput(requestIntegration) ||
                                        isLoading
                                    }
                                >
                                    Request
                                </LTButton>
                            </LTBox>
                        </form>
                    </LTBox>
                </LTModal>
            </LTBox>
            <div
                className='absolute left-[-999999px]'
                aria-live='polite'
                aria-atomic='true'
            >
                <span>
                    {bugTracker.length +
                        projectManagement.length +
                        analytics.length +
                        communication.length +
                        cicdTools.length +
                        codelessAutomationPlatform.length +
                        codelessAutomationTools.length +
                        testReporting.length +
                        thirdParty.length +
                        pluginsAndExtension.length}{' '}
                    Results found
                </span>
            </div>
        </>
    );
}

export default Home;
