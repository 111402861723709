import { useEffect, useState } from 'react';
import Icons from '../../../assets/images';
import Api from '../../../utils/api';
import Toaster from '../../Toaster/Index';
import { Label, LeftPanel } from '../../Wrappers';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    Octicon,
    Text,
    TextInput,
} from '@primer/react';
import { TrashIcon } from '@primer/octicons-react';
import { LTBox, LTButton, LTFormControl, LTIconButton, LTText } from '@lambdatestincprivate/lt-components';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

interface IWebhook {
    id: number;
    user_id: number;
    plugin_id: number;
    key: string;
    value: string;
    createdAt: string;
    updatedAt: string;
}

export default function Webhooks() {
    const [webhookURL, setWebhookURL] = useState('');
    const [webhookURLList, setWebhookURLList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await Api.installApi.fetchWebhooks();
                setWebhookURLList(res);
            } catch (err: any) {
                Toaster.error(err.response.data.message);
            }
        }
        fetchData();
    }, []);

    const handleSubmit = async (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await Api.installApi.toggleWebhooks(true);
            await Api.installApi.updateWebhooks(webhookURL);
            const res = await Api.installApi.fetchWebhooks();
            setWebhookURLList(res);
            setIsLoading(false);
            setWebhookURL('');
        } catch (err: any) {
            Toaster.error(err.response.data.message);
            setIsLoading(false);
        }
    };

    const handleDelete = async (url: string) => {
        try {
            await Api.installApi.deleteWebhooks(url);
            const res = await Api.installApi.fetchWebhooks();
            setWebhookURLList(res);
        } catch (err: any) {
            Toaster.error(err.response.data.message);
        }
    };

    return (
        <LeftPanel>
            <LTText
                as='h6'
                sx={{
                    borderBottom: 1,
                    borderStyle: 'solid',
                    borderColor: 'border.default',
                    paddingBottom: '16px',
                }}
            >
                Manage URLs
            </LTText>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
                onSubmit={handleSubmit}
            >
                <div className='flex space-y-2 mt-2'>
                    <LTFormControl
                        formStyles={{ width: '100%' }}
                        label='Webhook URL'
                        labelStyle={{ fontSize: 12, marginBottom: '4px' }}
                    >
                        <LTBox
                            display={'flex'}
                            width={'100%'}
                            sx={{ gap: '8px' }}
                        >
                            <LTTextInput
                                inputOnly={true}
                                type='text'
                                id='url'
                                aria-label='Webhook URL'
                                onChange={(value :any) => setWebhookURL(value)}
                                value={webhookURL}
                                required
                                width={'100%'}
                                placeholder='Add Webhooks'
                                contrast
                            />
                            <LTButton
                                variant={LTButton.Variant.DEFAULT}
                                disabled={isLoading}
                                onClick={handleSubmit}
                            >
                                Add URL
                            </LTButton>
                        </LTBox>
                    </LTFormControl>
                </div>

                {webhookURLList.length > 0 && (
                    <div className='my-4'>
                        <LTText sx={{ fontSize: 12, fontWeight: 600 }}>
                            Added URLs
                        </LTText>
                        <LTBox className='flex flex-col space-y-3 mt-3'>
                            {webhookURLList.map(
                                (element: IWebhook, index: number) => {
                                    return (
                                        <LTBox
                                            className={`flex justify-between items-center`}
                                        >
                                            <LTText fontSize={14}>
                                                {element.value}
                                            </LTText>
                                            <LTIconButton
                                                customStyle={{
                                                    color: '#CF212E !important',
                                                }}
                                                ariaLabel='Delete'
                                                // type='button'
                                                icon={TrashIcon}
                                                onClick={() =>
                                                    handleDelete(element.value)
                                                }
                                                variant='invisible'
                                            />
                                        </LTBox>
                                    );
                                }
                            )}
                        </LTBox>
                    </div>
                )}
            </form>
        </LeftPanel>
    );
}
