import { LTFormControl, LTText } from '@lambdatestincprivate/lt-components';
import { Label, LeftPanel } from '../../Wrappers';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Link,
    Text,
    TextInput,
} from '@primer/react';
import LTTextInput from '@lambdatestincprivate/lt-components/lib/components/LTInputBox';

export default function Shopify() {
    return (
        <LeftPanel>
            <form
                action=''
                className='flex flex-col space-y-4'
                id='form1'
            >
                <LTFormControl
                    label='Enter your shop domain to login and install this app'
                    labelStyle={{
                        fontSize: 12,
                    }}
                    formStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                    }}
                >
                    <LTTextInput
                        inputOnly={true}
                        type='text'
                        id='url'
                        aria-label='URL'
                        width={'100%'}
                        contrast
                        placeholder='shopname.myshopify.com'
                    />
                </LTFormControl>
                <Button
                    variant='primary'
                    type='submit'
                    form='form1'
                    className='self-start'
                >
                    Install
                </Button>
            </form>
            <div className='grid inline-block min-[686px]:absolute min-[686px]:bottom-0 max-[685px]:mt-[16px] mb-[16px]'>
                <LTText color={'muted'}>Know how to generate</LTText>
                <Link
					className='focus-black'
                    href='https://www.lambdatest.com/support/docs/shopify-integration'
                    target='_blank'
                    rel='noreferrer'
                >
                    Webhook URL
                </Link>
            </div>
        </LeftPanel>
    );
}
