import Api from './api';
import store from '../redux/store';
import { setPlugins, setSideBarObject } from '../redux/features/configSlice';
import { IList, IListItem } from '../assets/constants/constants';

export const buildCookieName = () => {
    const cookieName = process.env.REACT_APP_TOKEN_NAME || 'accessToken';
    return cookieName;
};

export const getCookie = (name: string) => {
    const result = new RegExp(
        '(?:^|; )' + encodeURIComponent(name) + '=([^;]*)'
    ).exec(document.cookie);
    return result ? result[1] : null;
};

export const searchCode = (apiList: any) => {
    const pathnames = window.location.pathname.split('/');
    if (pathnames.length === 3 || pathnames.length === 4) {
        const code = pathnames[1];
        for (const key of Object.keys(apiList)) {
            if (key !== 'MY INTEGRATIONS') {
                for (const element of apiList[key]) {
                    if (element.code === code) {
                        return element;
                    }
                }
            }
        }
    }
};

export const updatePluginList = async () => {
    const list = await Api.commonApi.fetchList();
    store.dispatch(setPlugins(filterInactivePlugins(list)));
    store.dispatch(setSideBarObject({}));
};

export const filterInactivePlugins = (list: IList) => {
    for (const key of [
        'MY INTEGRATIONS',
        'BUG TRACKER',
        'PROJECT MANAGEMENT',
        'ANALYTICS',
        'COMMUNICATION',
    ]) {
        list[key as keyof IList] = (list[key as keyof IList] as any).filter(
            (element: IListItem) => element.is_active === 1
        );
    }
    return list;
};

export const redirectToLogin = () => {
    const redirectUri = encodeURIComponent(window.location.origin);
    const state = encodeURIComponent(window.location.href);

    const redirectUrl = `${
        process.env.REACT_APP_ACCOUNTS_URL || 'https://accounts.lambdatest.com'
    }/oauth/authorize?client_id=3&redirect_uri=${redirectUri}&response_type=token&scope=&state=${state}`;
    window.location.href = redirectUrl;
};

export const searchRequestedIntegration = (
    apiList: any,
    integrationName: string
) => {
    for (const key of Object.keys(apiList)) {
        if (key !== 'MY INTEGRATIONS') {
            for (const element of apiList[key]) {
                if (
                    element.name.toLowerCase() === integrationName.toLowerCase()
                ) {
                    return element;
                }
            }
        }
    }
};

export const checkValidInput = (inputVal: string) => {
    if (inputVal.length < 1) {
        return false;
    }
    if (/^\d+$/.test(inputVal)) {
        return false;
    }
    if (/^[^a-zA-Z0-9]+$/.test(inputVal)) {
        return false;
    }
    return true;
};

export function sendAmplitudeEvents(eventName: string, data: any) {
    if ((window as any).amplitude) {
        (window as any).amplitude.getInstance().logEvent(eventName, data);
    }
}

export function sanitizeAndRedirect(url: string) {
    const urlPattern = /^(http|https):\/\//;
    if (!urlPattern.test(url)) {
        console.error('Invalid URL');
        return;
    }

    const allowedProtocols = ['http:', 'https:'];
    const { protocol, host, pathname, search, hash } = new URL(url);
    if (!allowedProtocols.includes(protocol)) {
        console.error('Invalid protocol');
        return;
    }

    const trustedDomains = ['github.com', 'gitlab.com', 'app.vssps.visualstudio.com'];

    if (!trustedDomains.includes(host)) {
        console.error('Invalid redirect URL');
        return;
    }

    const sanitizedUrl = `${protocol}//${host}${pathname}${search}${hash}`;

    window.location.href = sanitizedUrl;
}
